import api from "../../api"

export const createList = (params, data, toast, onFinished) => {
  api.post("/filter/start/", {
    filter: params.id,
    initial_count: data.count,
    file_extension: data.file_extension
  })
    .then((response) => {
      toast({
        title: "Solicitação criada com sucesso!",
        description: "O sistema está criando sua lista.",
        status: "success",
        position: 'bottom-right',
      });

      onFinished();
    })
    .catch((reason) => {
      toast({
        title: "Solicitação não foi criada!",
        description: reason.response.data?.non_field_errors,
        status: "error",
        position: 'bottom-right',
      });
    })
}

export const createPartialList = (params, data, toast, onFinished) => {
  api.post("/filter/partial/", { list: params.id, count: data.count, file_extension: data.file_extension })
    .then((response) => {
      toast({
        title: "Solicitação criada com sucesso!",
        description: "O sistema está criando sua lista.",
        status: "success",
        position: 'bottom-right',
      });
      
      onFinished();
    })
    .catch((reason) => {
      toast({
        title: "Solicitação não foi criada!",
        description: reason.response.data?.non_field_errors,
        status: "error",
        position: 'bottom-right',
      });
    })
}