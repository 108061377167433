export default [
  {
    "label":"0113000 - cultivo de cana-de-acucar",
    "value":"0113000"
  },
  {
    "label":"6920601 - atividades de contabilidade",
    "value":"6920601"
  },
  {
    "label":"4789004 - comercio varejista de animais vivos e de artigos e alimentos para animais de estimacao",
    "value":"4789004"
  },
  {
    "label":"8712300 - atividades de fornecimento de infra-estrutura de apoio e assistencia a paciente no domicilio",
    "value":"8712300"
  },
  {
    "label":"4923001 - servico de taxi",
    "value":"4923001"
  },
  {
    "label":"4789001 - comercio varejista de suvenires, bijuterias e artesanatos",
    "value":"4789001"
  },
  {
    "label":"4722901 - comercio varejista de carnes - acougues",
    "value":"4722901"
  },
  {
    "label":"7112000 - servicos de engenharia",
    "value":"7112000"
  },
  {
    "label":"3299099 - fabricacao de produtos diversos nao especificados anteriormente",
    "value":"3299099"
  },
  {
    "label":"7420001 - atividades de producao de fotografias, exceto aerea e submarina",
    "value":"7420001"
  },
  {
    "label":"6462000 - holdings de instituicoes nao-financeiras",
    "value":"6462000"
  },
  {
    "label":"4929901 - transporte rodoviario coletivo de passageiros, sob regime de fretamento, municipal",
    "value":"4929901"
  },
  {
    "label":"7911200 - agencias de viagens",
    "value":"7911200"
  },
  {
    "label":"4924800 - transporte escolar",
    "value":"4924800"
  },
  {
    "label":"9602501 - cabeleireiros, manicure e pedicure",
    "value":"9602501"
  },
  {
    "label":"5611203 - lanchonetes, casas de cha, de sucos e similares",
    "value":"5611203"
  },
  {
    "label":"8112500 - condominios prediais",
    "value":"8112500"
  },
  {
    "label":"4723700 - comercio varejista de bebidas",
    "value":"4723700"
  },
  {
    "label":"4930202 - transporte rodoviario de carga, exceto produtos perigosos e mudancas, intermunicipal, interestadual e internacional",
    "value":"4930202"
  },
  {
    "label":"4321500 - instalacao e manutencao eletrica",
    "value":"4321500"
  },
  {
    "label":"9430800 - atividades de associacoes de defesa de direitos sociais",
    "value":"9430800"
  },
  {
    "label":"4772500 - comercio varejista de cosmeticos, produtos de perfumaria e de higiene pessoal",
    "value":"4772500"
  },
  {
    "label":"4930201 - transporte rodoviario de carga, exceto produtos perigosos e mudancas, municipal.",
    "value":"4930201"
  },
  {
    "label":"5612100 - servicos ambulantes de alimentacao",
    "value":"5612100"
  },
  {
    "label":"8219999 - preparacao de documentos e servicos especializados de apoio administrativo nao especificados anteriormente",
    "value":"8219999"
  },
  {
    "label":"4520001 - servicos de manutencao e reparacao mecanica de veiculos automotores",
    "value":"4520001"
  },
  {
    "label":"4729699 - comercio varejista de produtos alimenticios em geral ou especializado em produtos alimenticios nao especificados anteriormente",
    "value":"4729699"
  },
  {
    "label":"8230001 - servicos de organizacao de feiras, congressos, exposicoes e festas",
    "value":"8230001"
  },
  {
    "label":"4789099 - comercio varejista de outros produtos nao especificados anteriormente",
    "value":"4789099"
  },
  {
    "label":"4744099 - comercio varejista de materiais de construcao em geral",
    "value":"4744099"
  },
  {
    "label":"5611204 - bares e outros estabelecimentos especializados em servir bebidas, sem entretenimento",
    "value":"5611204"
  },
  {
    "label":"4120400 - construcao de edificios",
    "value":"4120400"
  },
  {
    "label":"4330404 - servicos de pintura de edificios em geral",
    "value":"4330404"
  },
  {
    "label":"8599604 - treinamento em desenvolvimento profissional e gerencial",
    "value":"8599604"
  },
  {
    "label":"8211300 - servicos combinados de escritorio e apoio administrativo",
    "value":"8211300"
  },
  {
    "label":"9700500 - servicos domesticos",
    "value":"9700500"
  },
  {
    "label":"9491000 - atividades de organizacoes religiosas ou filosoficas",
    "value":"9491000"
  },
  {
    "label":"5320202 - servicos de entrega rapida",
    "value":"5320202"
  },
  {
    "label":"0151201 - criacao de bovinos para corte",
    "value":"0151201"
  },
  {
    "label":"9511800 - reparacao e manutencao de computadores e de equipamentos perifericos",
    "value":"9511800"
  },
  {
    "label":"4771701 - comercio varejista de produtos farmaceuticos, sem manipulacao de formulas",
    "value":"4771701"
  },
  {
    "label":"4755502 - comercio varejista de artigos de armarinho",
    "value":"4755502"
  },
  {
    "label":"4110700 - incorporacao de empreendimentos imobiliarios",
    "value":"4110700"
  },
  {
    "label":"3101200 - fabricacao de moveis com predominancia de madeira",
    "value":"3101200"
  },
  {
    "label":"4754701 - comercio varejista de moveis",
    "value":"4754701"
  },
  {
    "label":"8630503 - atividade medica ambulatorial restrita a consultas",
    "value":"8630503"
  },
  {
    "label":"1412602 - confeccao, sob medida, de pecas do vestuario, exceto roupas intimas",
    "value":"1412602"
  },
  {
    "label":"4724500 - comercio varejista de hortifrutigranjeiros",
    "value":"4724500"
  },
  {
    "label":"6911701 - servicos advocaticios",
    "value":"6911701"
  },
  {
    "label":"2542000 - fabricacao de artigos de serralheria, exceto esquadrias",
    "value":"2542000"
  },
  {
    "label":"4752100 - comercio varejista especializado de equipamentos de telefonia e comunicacao",
    "value":"4752100"
  },
  {
    "label":"4520002 - servicos de lanternagem ou funilaria e pintura de veiculos automotores",
    "value":"4520002"
  },
  {
    "label":"4755503 - comercio varejista de artigos de cama, mesa e banho",
    "value":"4755503"
  },
  {
    "label":"7319003 - marketing direto",
    "value":"7319003"
  },
  {
    "label":"4774100 - comercio varejista de artigos de optica",
    "value":"4774100"
  },
  {
    "label":"9313100 - atividades de condicionamento fisico",
    "value":"9313100"
  },
  {
    "label":"8130300 - atividades paisagisticas",
    "value":"8130300"
  },
  {
    "label":"4753900 - comercio varejista especializado de eletrodomesticos e equipamentos de audio e video",
    "value":"4753900"
  },
  {
    "label":"5611202 - bares e outros estabelecimentos especializados em servir bebidas",
    "value":"5611202"
  },
  {
    "label":"4399103 - obras de alvenaria",
    "value":"4399103"
  },
  {
    "label":"7319002 - promocao de vendas",
    "value":"7319002"
  },
  {
    "label":"4782201 - comercio varejista de calcados",
    "value":"4782201"
  },
  {
    "label":"4721102 - padaria e confeitaria com predominancia de revenda",
    "value":"4721102"
  },
  {
    "label":"4520005 - servicos de lavagem, lubrificacao e polimento de veiculos automotores",
    "value":"4520005"
  },
  {
    "label":"4744001 - comercio varejista de ferragens e ferramentas",
    "value":"4744001"
  },
  {
    "label":"5611201 - restaurantes e similares",
    "value":"5611201"
  },
  {
    "label":"5620104 - fornecimento de alimentos preparados preponderantemente para consumo domiciliar",
    "value":"5620104"
  },
  {
    "label":"0134200 - cultivo de cafe",
    "value":"0134200"
  },
  {
    "label":"8650004 - atividades de fisioterapia",
    "value":"8650004"
  },
  {
    "label":"8291100 - atividades de cobrancas e informacoes cadastrais",
    "value":"8291100"
  },
  {
    "label":"8630504 - atividade odontologica",
    "value":"8630504"
  },
  {
    "label":"5819100 - edicao de cadastros, listas e de outros produtos graficos",
    "value":"5819100"
  },
  {
    "label":"4761003 - comercio varejista de artigos de papelaria",
    "value":"4761003"
  },
  {
    "label":"4929902 - transporte rodoviario coletivo de passageiros, sob regime de fretamento, intermunicipal, interestadual e internacional",
    "value":"4929902"
  },
  {
    "label":"6810202 - aluguel de imoveis proprios",
    "value":"6810202"
  },
  {
    "label":"9521500 - reparacao e manutencao de equipamentos eletroeletronicos de uso pessoal e domestico",
    "value":"9521500"
  },
  {
    "label":"4789005 - comercio varejista de produtos saneantes domissanitarios",
    "value":"4789005"
  },
  {
    "label":"6821801 - corretagem na compra e venda e avaliacao de imoveis",
    "value":"6821801"
  },
  {
    "label":"6810201 - compra e venda de imoveis proprios",
    "value":"6810201"
  },
  {
    "label":"8411600 - administracao publica em geral",
    "value":"8411600"
  },
  {
    "label":"9609208 - higiene e embelezamento de animais domesticos",
    "value":"9609208"
  },
  {
    "label":"3329501 - servicos de montagem de moveis de qualquer material",
    "value":"3329501"
  },
  {
    "label":"4754702 - comercio varejista de artigos de colchoaria",
    "value":"4754702"
  },
  {
    "label":"4330403 - obras de acabamento em gesso e estuque",
    "value":"4330403"
  },
  {
    "label":"4511102 - comercio a varejo de automoveis, camionetas e utilitarios usados",
    "value":"4511102"
  },
  {
    "label":"4520007 - servicos de instalacao, manutencao e reparacao de acessorios para veiculos automotores",
    "value":"4520007"
  },
  {
    "label":"4721103 - comercio varejista de laticinios e frios",
    "value":"4721103"
  },
  {
    "label":"4763601 - comercio varejista de brinquedos e artigos recreativos",
    "value":"4763601"
  },
  {
    "label":"4743100 - comercio varejista de vidros",
    "value":"4743100"
  },
  {
    "label":"4789002 - comercio varejista de plantas e flores naturais",
    "value":"4789002"
  },
  {
    "label":"4757100 - comercio varejista especializado de pecas e acessorios para aparelhos eletroeletronicos para uso domestico, exceto informatica e comunicacao",
    "value":"4757100"
  },
  {
    "label":"6201501 - desenvolvimento de programas de computador sob encomenda",
    "value":"6201501"
  },
  {
    "label":"8599603 - treinamento em informatica",
    "value":"8599603"
  },
  {
    "label":"3321000 - instalacao de maquinas e equipamentos industriais",
    "value":"3321000"
  },
  {
    "label":"4322301 - instalacoes hidraulicas, sanitarias e de gas",
    "value":"4322301"
  },
  {
    "label":"4763603 - comercio varejista de bicicletas e triciclos; pecas e acessorios",
    "value":"4763603"
  },
  {
    "label":"8593700 - ensino de idiomas",
    "value":"8593700"
  },
  {
    "label":"8513900 - ensino fundamental",
    "value":"8513900"
  },
  {
    "label":"4744002 - comercio varejista de madeira e artefatos",
    "value":"4744002"
  },
  {
    "label":"7490104 - atividades de intermediacao e agenciamento de servicos e negocios em geral, exceto imobiliarios",
    "value":"7490104"
  },
  {
    "label":"8512100 - educacao infantil - pre-escola",
    "value":"8512100"
  },
  {
    "label":"6822600 - gestao e administracao da propriedade imobiliaria",
    "value":"6822600"
  },
  {
    "label":"4541205 - comercio a varejo de pecas e acessorios para motocicletas e motonetas",
    "value":"4541205"
  },
  {
    "label":"4322302 - instalacao e manutencao de sistemas centrais de ar condicionado, de ventilacao e refrigeracao",
    "value":"4322302"
  },
  {
    "label":"4784900 - comercio varejista de gas liquefeito de petroleo (glp)",
    "value":"4784900"
  },
  {
    "label":"4713002 - lojas de variedades, exceto lojas de departamentos ou magazines",
    "value":"4713002"
  },
  {
    "label":"0151202 - criacao de bovinos para leite",
    "value":"0151202"
  },
  {
    "label":"8592999 - ensino de arte e cultura nao especificado anteriormente",
    "value":"8592999"
  },
  {
    "label":"9512600 - reparacao e manutencao de equipamentos de comunicacao",
    "value":"9512600"
  },
  {
    "label":"5620102 - servicos de alimentacao para eventos e recepcoes - bufe",
    "value":"5620102"
  },
  {
    "label":"4759899 - comercio varejista de outros artigos de uso pessoal e domestico nao especificados anteriormente",
    "value":"4759899"
  },
  {
    "label":"5229099 - outras atividades auxiliares dos transportes terrestres nao especificadas anteriormente",
    "value":"5229099"
  },
  {
    "label":"2539001 - servicos de usinagem, tornearia e solda",
    "value":"2539001"
  },
  {
    "label":"4541206 - comercio a varejo de pecas e acessorios novos para  motocicletas e motonetas",
    "value":"4541206"
  },
  {
    "label":"1091101 - fabricacao de produtos de panificacao industrial",
    "value":"1091101"
  },
  {
    "label":"5223100 - estacionamento de veiculos",
    "value":"5223100"
  },
  {
    "label":"4520006 - servicos de borracharia para veiculos automotores",
    "value":"4520006"
  },
  {
    "label":"1412603 - faccao de pecas do vestuario, exceto roupas intimas",
    "value":"1412603"
  },
  {
    "label":"4742300 - comercio varejista de material eletrico",
    "value":"4742300"
  },
  {
    "label":"9609299 - outras atividades de servicos pessoais nao especificadas anteriormente",
    "value":"9609299"
  },
  {
    "label":"4617600 - representantes comerciais e agentes do comercio de produtos alimenticios, bebidas e fumo",
    "value":"4617600"
  },
  {
    "label":"2512800 - fabricacao de esquadrias de metal",
    "value":"2512800"
  },
  {
    "label":"1359600 - fabricacao de outros produtos texteis nao especificados anteriormente",
    "value":"1359600"
  },
  {
    "label":"5510801 - hoteis",
    "value":"5510801"
  },
  {
    "label":"0121101 - horticultura, exceto morango",
    "value":"0121101"
  },
  {
    "label":"4330499 - outras obras de acabamento da construcao",
    "value":"4330499"
  },
  {
    "label":"0111302 - cultivo de milho",
    "value":"0111302"
  },
  {
    "label":"5620101 - fornecimento de alimentos preparados preponderantemente para empresas",
    "value":"5620101"
  },
  {
    "label":"4543900 - manutencao e reparacao de motocicletas e motonetas",
    "value":"4543900"
  },
  {
    "label":"4783101 - comercio varejista de artigos de joalheria",
    "value":"4783101"
  },
  {
    "label":"5320201 - servicos de malote nao realizados pelo correio nacional",
    "value":"5320201"
  },
  {
    "label":"4530704 - comercio a varejo de pecas e acessorios usados para veiculos automotores",
    "value":"4530704"
  },
  {
    "label":"4639701 - comercio atacadista de produtos alimenticios em geral",
    "value":"4639701"
  },
  {
    "label":"4771704 - comercio varejista de medicamentos veterinarios",
    "value":"4771704"
  },
  {
    "label":"4616800 - representantes comerciais e agentes do comercio de texteis, vestuario, calcados e artigos de viagem",
    "value":"4616800"
  },
  {
    "label":"3811400 - coleta de residuos nao-perigosos",
    "value":"3811400"
  },
  {
    "label":"4741500 - comercio varejista de tintas e materiais para pintura",
    "value":"4741500"
  },
  {
    "label":"5611205 - bares e outros estabelecimentos especializados em servir bebidas, com entretenimento",
    "value":"5611205"
  },
  {
    "label":"1821100 - servicos de pre-impressao",
    "value":"1821100"
  },
  {
    "label":"1340599 - outros servicos de acabamento em fios, tecidos, artefatos texteis e pecas do vestuario",
    "value":"1340599"
  },
  {
    "label":"8511200 - educacao infantil - creche",
    "value":"8511200"
  },
  {
    "label":"8592903 - ensino de musica",
    "value":"8592903"
  },
  {
    "label":"8610101 - atividades de atendimento hospitalar, exceto pronto-socorro e unidades para atendimento a urgencias",
    "value":"8610101"
  },
  {
    "label":"4759801 - comercio varejista de artigos de tapecaria, cortinas e persianas",
    "value":"4759801"
  },
  {
    "label":"6319400 - portais, provedores de conteudo e outros servicos de informacao na internet",
    "value":"6319400"
  },
  {
    "label":"8129000 - atividades de limpeza nao especificadas anteriormente",
    "value":"8129000"
  },
  {
    "label":"8219901 - fotocopias",
    "value":"8219901"
  },
  {
    "label":"1411801 - confeccao de roupas intimas",
    "value":"1411801"
  },
  {
    "label":"4313400 - obras de terraplenagem",
    "value":"4313400"
  },
  {
    "label":"8599601 - formacao de condutores",
    "value":"8599601"
  },
  {
    "label":"8121400 - limpeza em predios e em domicilios",
    "value":"8121400"
  },
  {
    "label":"2391503 - aparelhamento de placas e execucao de trabalhos em marmore, granito, ardosia e outras pedras",
    "value":"2391503"
  },
  {
    "label":"5229002 - servicos de reboque de veiculos",
    "value":"5229002"
  },
  {
    "label":"8111700 - servicos combinados para apoio a edificios, exceto condominios prediais",
    "value":"8111700"
  },
  {
    "label":"0139306 - cultivo de seringueira",
    "value":"0139306"
  },
  {
    "label":"5812301 - edicao de jornais diarios",
    "value":"5812301"
  },
  {
    "label":"4541203 - comercio a varejo de motocicletas e motonetas novas",
    "value":"4541203"
  },
  {
    "label":"0115600 - cultivo de soja",
    "value":"0115600"
  },
  {
    "label":"9420100 - atividades de organizacoes sindicais",
    "value":"9420100"
  },
  {
    "label":"9001906 - atividades de sonorizacao e de iluminacao",
    "value":"9001906"
  },
  {
    "label":"1813099 - impressao de material para outros usos",
    "value":"1813099"
  },
  {
    "label":"1813001 - impressao de material para uso publicitario",
    "value":"1813001"
  },
  {
    "label":"0131800 - cultivo de laranja",
    "value":"0131800"
  },
  {
    "label":"0161003 - servico de preparacao de terreno, cultivo e colheita",
    "value":"0161003"
  },
  {
    "label":"4930204 - transporte rodoviario de mudancas",
    "value":"4930204"
  },
  {
    "label":"4761001 - comercio varejista de livros",
    "value":"4761001"
  },
  {
    "label":"9529105 - reparacao de artigos do mobiliario",
    "value":"9529105"
  },
  {
    "label":"9329899 - outras atividades de recreacao e lazer nao especificadas anteriormente",
    "value":"9329899"
  },
  {
    "label":"9601701 - lavanderias",
    "value":"9601701"
  },
  {
    "label":"9609206 - servicos de tatuagem e colocacao de piercing",
    "value":"9609206"
  },
  {
    "label":"4729601 - tabacaria",
    "value":"4729601"
  },
  {
    "label":"8630502 - atividade medica ambulatorial com recursos para realizacao de exames complementares",
    "value":"8630502"
  },
  {
    "label":"9529102 - chaveiros",
    "value":"9529102"
  },
  {
    "label":"3314707 - manutencao e reparacao de maquinas e aparelhos de refrigeracao e ventilacao para uso industrial e comercial",
    "value":"3314707"
  },
  {
    "label":"9312300 - clubes sociais, esportivos e similares",
    "value":"9312300"
  },
  {
    "label":"8640202 - laboratorios clinicos",
    "value":"8640202"
  },
  {
    "label":"7111100 - servicos de arquitetura",
    "value":"7111100"
  },
  {
    "label":"4785799 - comercio varejista de outros artigos usados",
    "value":"4785799"
  },
  {
    "label":"1096100 - fabricacao de alimentos e pratos prontos",
    "value":"1096100"
  },
  {
    "label":"8599605 - cursos preparatorios para concursos",
    "value":"8599605"
  },
  {
    "label":"4330402 - instalacao de portas, janelas, tetos, divisorias e armarios embutidos de qualquer material",
    "value":"4330402"
  },
  {
    "label":"5590699 - outros alojamentos nao especificados anteriormente",
    "value":"5590699"
  },
  {
    "label":"4530705 - comercio a varejo de pneumaticos e camaras-de-ar",
    "value":"4530705"
  },
  {
    "label":"7311400 - agencias de publicidade",
    "value":"7311400"
  },
  {
    "label":"4773300 - comercio varejista de artigos medicos e ortopedicos",
    "value":"4773300"
  },
  {
    "label":"7729202 - aluguel de moveis, utensilios e aparelhos de uso domestico e pessoal; instrumentos musicais",
    "value":"7729202"
  },
  {
    "label":"1094500 - fabricacao de massas alimenticias",
    "value":"1094500"
  },
  {
    "label":"7732201 - aluguel de maquinas e equipamentos para construcao sem operador, exceto andaimes",
    "value":"7732201"
  },
  {
    "label":"4722902 - peixaria",
    "value":"4722902"
  },
  {
    "label":"5811500 - edicao de livros",
    "value":"5811500"
  },
  {
    "label":"8550301 - administracao de caixas escolares",
    "value":"8550301"
  },
  {
    "label":"8650003 - atividades de psicologia e psicanalise",
    "value":"8650003"
  },
  {
    "label":"4929999 - outros transportes rodoviarios de passageiros nao especificados anteriormente",
    "value":"4929999"
  },
  {
    "label":"4530701 - comercio por atacado de pecas e acessorios novos para veiculos automotores",
    "value":"4530701"
  },
  {
    "label":"6422100 - bancos multiplos, com carteira comercial",
    "value":"6422100"
  },
  {
    "label":"7739099 - aluguel de outras maquinas e equipamentos comerciais e industriais nao especificados anteriormente, sem operador",
    "value":"7739099"
  },
  {
    "label":"5813100 - edicao de revistas",
    "value":"5813100"
  },
  {
    "label":"4755501 - comercio varejista de tecidos",
    "value":"4755501"
  },
  {
    "label":"6311900 - tratamento de dados, provedores de servicos de aplicacao e servicos de hospedagem na internet",
    "value":"6311900"
  },
  {
    "label":"6463800 - outras sociedades de participacao, exceto holdings",
    "value":"6463800"
  },
  {
    "label":"7711000 - locacao de automoveis sem condutor",
    "value":"7711000"
  },
  {
    "label":"7119703 - servicos de desenho tecnico relacionados a arquitetura e engenharia",
    "value":"7119703"
  },
  {
    "label":"8640205 - servicos de diagnostico por imagem com uso de radiacao ionizante, exceto tomografia",
    "value":"8640205"
  },
  {
    "label":"8650099 - atividades de profissionais da area de saude nao especificadas anteriormente",
    "value":"8650099"
  },
  {
    "label":"1052000 - fabricacao de laticinios",
    "value":"1052000"
  },
  {
    "label":"3313901 - manutencao e reparacao de geradores, transformadores e motores eletricos",
    "value":"3313901"
  },
  {
    "label":"1539400 - fabricacao de calcados de materiais nao especificados anteriormente",
    "value":"1539400"
  },
  {
    "label":"6611802 - bolsa de mercadorias",
    "value":"6611802"
  },
  {
    "label":"4921301 - transporte rodoviario coletivo de passageiros, com itinerario fixo, municipal",
    "value":"4921301"
  },
  {
    "label":"4930203 - transporte rodoviario de produtos perigosos",
    "value":"4930203"
  },
  {
    "label":"6912500 - cartorios",
    "value":"6912500"
  },
  {
    "label":"5620103 - cantinas - servicos de alimentacao privativos",
    "value":"5620103"
  },
  {
    "label":"7500100 - atividades veterinarias",
    "value":"7500100"
  },
  {
    "label":"6110803 - servicos de comunicacao multimidia - scm",
    "value":"6110803"
  },
  {
    "label":"2330399 - fabricacao de outros artefatos e produtos de concreto, cimento, fibrocimento, gesso e materiais semelhantes",
    "value":"2330399"
  },
  {
    "label":"8800600 - servicos de assistencia social sem alojamento",
    "value":"8800600"
  },
  {
    "label":"9001901 - producao teatral",
    "value":"9001901"
  },
  {
    "label":"0119999 - cultivo de outras plantas de lavoura temporaria nao especificadas anteriormente",
    "value":"0119999"
  },
  {
    "label":"9001999 - artes cenicas, espetaculos e atividades complementares nao especificadas anteriormente",
    "value":"9001999"
  },
  {
    "label":"7490103 - servicos de agronomia e de consultoria as atividades agricolas e pecuarias",
    "value":"7490103"
  },
  {
    "label":"8122200 - imunizacao e controle de pragas urbanas",
    "value":"8122200"
  },
  {
    "label":"4618401 - representantes comerciais e agentes do comercio de medicamentos, cosmeticos e produtos de perfumaria",
    "value":"4618401"
  },
  {
    "label":"3299003 - fabricacao de letras, letreiros e placas de qualquer material, exceto luminosos",
    "value":"3299003"
  },
  {
    "label":"1531901 - fabricacao de calcados de couro",
    "value":"1531901"
  },
  {
    "label":"6202300 - desenvolvimento e licenciamento de programas de computador customizaveis",
    "value":"6202300"
  },
  {
    "label":"4744004 - comercio varejista de cal, areia, pedra britada, tijolos e telhas",
    "value":"4744004"
  },
  {
    "label":"4763604 - comercio varejista de artigos de caca, pesca e camping",
    "value":"4763604"
  },
  {
    "label":"8020001 - atividades de monitoramento de sistemas de seguranca eletronico",
    "value":"8020001"
  },
  {
    "label":"9412099 - outras atividades associativas profissionais",
    "value":"9412099"
  },
  {
    "label":"4663000 - comercio atacadista de maquinas e equipamentos para uso industrial; partes e pecas",
    "value":"4663000"
  },
  {
    "label":"4761002 - comercio varejista de jornais e revistas",
    "value":"4761002"
  },
  {
    "label":"6399200 - outras atividades de prestacao de servicos de informacao nao especificadas anteriormente",
    "value":"6399200"
  },
  {
    "label":"7723300 - aluguel de objetos do vestuario, joias e acessorios",
    "value":"7723300"
  },
  {
    "label":"1351100 - fabricacao de artefatos texteis para uso domestico",
    "value":"1351100"
  },
  {
    "label":"4789008 - comercio varejista de artigos fotograficos e para filmagem",
    "value":"4789008"
  },
  {
    "label":"0210101 - cultivo de eucalipto",
    "value":"0210101"
  },
  {
    "label":"4789007 - comercio varejista de equipamentos para escritorio",
    "value":"4789007"
  },
  {
    "label":"6203100 - desenvolvimento e licenciamento de programas de computador nao-customizaveis",
    "value":"6203100"
  },
  {
    "label":"5812302 - edicao de jornais nao diarios",
    "value":"5812302"
  },
  {
    "label":"6810203 - loteamento de imoveis proprios",
    "value":"6810203"
  },
  {
    "label":"9319101 - producao e promocao de eventos esportivos",
    "value":"9319101"
  },
  {
    "label":"1093701 - fabricacao de produtos derivados do cacau e de chocolates",
    "value":"1093701"
  },
  {
    "label":"8630501 - atividade medica ambulatorial com recursos para realizacao de procedimentos cirurgicos",
    "value":"8630501"
  },
  {
    "label":"8299706 - casas lotericas",
    "value":"8299706"
  },
  {
    "label":"7420004 - filmagem de festas e eventos",
    "value":"7420004"
  },
  {
    "label":"7490199 - outras atividades profissionais, cientificas e tecnicas nao especificadas anteriormente",
    "value":"7490199"
  },
  {
    "label":"8610102 - atividades de atendimento em pronto-socorro e unidades hospitalares para atendimento a urgencias",
    "value":"8610102"
  },
  {
    "label":"6821802 - corretagem no aluguel de imoveis",
    "value":"6821802"
  },
  {
    "label":"8299707 - salas de acesso a internet",
    "value":"8299707"
  },
  {
    "label":"1340501 - estamparia e texturizacao em fios, tecidos, artefatos texteis e pecas do vestuario",
    "value":"1340501"
  },
  {
    "label":"8011101 - atividades de vigilancia e seguranca privada",
    "value":"8011101"
  },
  {
    "label":"9493600 - atividades de organizacoes associativas ligadas a cultura e a arte",
    "value":"9493600"
  },
  {
    "label":"4711301 - comercio varejista de mercadorias em geral, com predominancia de produtos alimenticios - hipermercados",
    "value":"4711301"
  },
  {
    "label":"2599399 - fabricacao de outros produtos de metal nao especificados anteriormente",
    "value":"2599399"
  },
  {
    "label":"8630599 - atividades de atencao ambulatorial nao especificadas anteriormente",
    "value":"8630599"
  },
  {
    "label":"9603304 - servicos de funerarias",
    "value":"9603304"
  },
  {
    "label":"7912100 - operadores turisticos",
    "value":"7912100"
  },
  {
    "label":"4923002 - servico de transporte de passageiros - locacao de automoveis com motorista",
    "value":"4923002"
  },
  {
    "label":"2229399 - fabricacao de artefatos de material plastico para outros usos nao especificados anteriormente",
    "value":"2229399"
  },
  {
    "label":"4693100 - comercio atacadista de mercadorias em geral, sem predominancia de alimentos ou de insumos agropecuarios",
    "value":"4693100"
  },
  {
    "label":"4642701 - comercio atacadista de artigos do vestuario e acessorios, exceto profissionais e de seguranca",
    "value":"4642701"
  },
  {
    "label":"4686902 - comercio atacadista de embalagens",
    "value":"4686902"
  },
  {
    "label":"1092900 - fabricacao de biscoitos e bolachas",
    "value":"1092900"
  },
  {
    "label":"2543800 - fabricacao de ferramentas",
    "value":"2543800"
  },
  {
    "label":"3314711 - manutencao e reparacao de maquinas e equipamentos para agricultura e pecuaria",
    "value":"3314711"
  },
  {
    "label":"8541400 - educacao profissional de nivel tecnico",
    "value":"8541400"
  },
  {
    "label":"0133402 - cultivo de banana",
    "value":"0133402"
  },
  {
    "label":"4754703 - comercio varejista de artigos de iluminacao",
    "value":"4754703"
  },
  {
    "label":"4661300 - comercio atacadista de maquinas, aparelhos e equipamentos para uso agropecuario; partes e pecas",
    "value":"4661300"
  },
  {
    "label":"4615000 - representantes comerciais e agentes do comercio de eletrodomesticos, moveis e artigos de uso domestico",
    "value":"4615000"
  },
  {
    "label":"2330301 - fabricacao de estruturas pre-moldadas de concreto armado, em serie e sob encomenda",
    "value":"2330301"
  },
  {
    "label":"3313999 - manutencao e reparacao de maquinas, aparelhos e materiais eletricos nao especificados anteriormente",
    "value":"3313999"
  },
  {
    "label":"4713001 - lojas de departamentos ou magazines",
    "value":"4713001"
  },
  {
    "label":"5211701 - armazens gerais - emissao de warrant",
    "value":"5211701"
  },
  {
    "label":"1610201 - serrarias com desdobramento de madeira",
    "value":"1610201"
  },
  {
    "label":"4299599 - outras obras de engenharia civil nao especificadas anteriormente",
    "value":"4299599"
  },
  {
    "label":"4744003 - comercio varejista de materiais hidraulicos",
    "value":"4744003"
  },
  {
    "label":"6619302 - correspondentes de instituicoes financeiras",
    "value":"6619302"
  },
  {
    "label":"4783102 - comercio varejista de artigos de relojoaria",
    "value":"4783102"
  },
  {
    "label":"1031700 - fabricacao de conservas de frutas",
    "value":"1031700"
  },
  {
    "label":"8711502 - instituicoes de longa permanencia para idosos",
    "value":"8711502"
  },
  {
    "label":"2599301 - servicos de confeccao de armacoes metalicas para a construcao",
    "value":"2599301"
  },
  {
    "label":"0161099 - atividades de apoio a agricultura nao especificadas anteriormente",
    "value":"0161099"
  },
  {
    "label":"9411100 - atividades de organizacoes associativas patronais e empresariais",
    "value":"9411100"
  },
  {
    "label":"5310501 - atividades do correio nacional",
    "value":"5310501"
  },
  {
    "label":"0810006 - extracao de areia, cascalho ou pedregulho e beneficiamento associado",
    "value":"0810006"
  },
  {
    "label":"4762800 - comercio varejista de discos, cds, dvds e fitas",
    "value":"4762800"
  },
  {
    "label":"1352900 - fabricacao de artefatos de tapecaria",
    "value":"1352900"
  },
  {
    "label":"3314710 - manutencao e reparacao de maquinas e equipamentos para uso geral nao especificados anteriormente",
    "value":"3314710"
  },
  {
    "label":"2330302 - fabricacao de artefatos de cimento para uso na construcao",
    "value":"2330302"
  },
  {
    "label":"4645101 - comercio atacadista de instrumentos e materiais para uso medico, cirurgico, hospitalar e de laboratorios",
    "value":"4645101"
  },
  {
    "label":"4683400 - comercio atacadista de defensivos agricolas, adubos, fertilizantes e corretivos do solo",
    "value":"4683400"
  },
  {
    "label":"8591100 - ensino de esportes",
    "value":"8591100"
  },
  {
    "label":"4732600 - comercio varejista de lubrificantes",
    "value":"4732600"
  },
  {
    "label":"0155501 - criacao de frangos para corte",
    "value":"0155501"
  },
  {
    "label":"7990200 - servicos de reservas e outros servicos de turismo nao especificados anteriormente",
    "value":"7990200"
  },
  {
    "label":"6010100 - atividades de radio",
    "value":"6010100"
  },
  {
    "label":"9319199 - outras atividades esportivas nao especificadas anteriormente",
    "value":"9319199"
  },
  {
    "label":"7722500 - aluguel de fitas de video, dvds e similares",
    "value":"7722500"
  },
  {
    "label":"0133404 - cultivo de citricos, exceto laranja",
    "value":"0133404"
  },
  {
    "label":"1529700 - fabricacao de artefatos de couro nao especificados anteriormente",
    "value":"1529700"
  },
  {
    "label":"1531902 - acabamento de calcados de couro sob contrato",
    "value":"1531902"
  },
  {
    "label":"4789003 - comercio varejista de objetos de arte",
    "value":"4789003"
  },
  {
    "label":"2532201 - producao de artefatos estampados de metal",
    "value":"2532201"
  },
  {
    "label":"4611700 - representantes comerciais e agentes do comercio de materias-primas agricolas e animais vivos",
    "value":"4611700"
  },
  {
    "label":"2511000 - fabricacao de estruturas metalicas",
    "value":"2511000"
  },
  {
    "label":"4614100 - representantes comerciais e agentes do comercio de maquinas, equipamentos, embarcacoes e aeronaves",
    "value":"4614100"
  },
  {
    "label":"4292801 - montagem de estruturas metalicas",
    "value":"4292801"
  },
  {
    "label":"8690999 - outras atividades de atencao a saude humana nao especificadas anteriormente",
    "value":"8690999"
  },
  {
    "label":"0132600 - cultivo de uva",
    "value":"0132600"
  },
  {
    "label":"4679699 - comercio atacadista de materiais de construcao em geral",
    "value":"4679699"
  },
  {
    "label":"1099699 - fabricacao de outros produtos alimenticios nao especificados anteriormente",
    "value":"1099699"
  },
  {
    "label":"8412400 - regulacao das atividades de saude, educacao, servicos culturais e outros servicos sociais",
    "value":"8412400"
  },
  {
    "label":"1053800 - fabricacao de sorvetes e outros gelados comestiveis",
    "value":"1053800"
  },
  {
    "label":"8520100 - ensino medio",
    "value":"8520100"
  },
  {
    "label":"3839499 - recuperacao de materiais nao especificados anteriormente",
    "value":"3839499"
  },
  {
    "label":"5590603 - pensoes (alojamento)",
    "value":"5590603"
  },
  {
    "label":"1521100 - fabricacao de artigos para viagem, bolsas e semelhantes de qualquer material",
    "value":"1521100"
  },
  {
    "label":"6190601 - provedores de acesso as redes de comunicacoes",
    "value":"6190601"
  },
  {
    "label":"3511501 - geracao de energia eletrica",
    "value":"3511501"
  },
  {
    "label":"4687703 - comercio atacadista de residuos e sucatas metalicos",
    "value":"4687703"
  },
  {
    "label":"3314702 - manutencao e reparacao de equipamentos hidraulicos e pneumaticos, exceto valvulas",
    "value":"3314702"
  },
  {
    "label":"1013901 - fabricacao de produtos de carne",
    "value":"1013901"
  },
  {
    "label":"7490101 - servicos de traducao, interpretacao e similares",
    "value":"7490101"
  },
  {
    "label":"4213800 - obras de urbanizacao - ruas, pracas e calcadas",
    "value":"4213800"
  },
  {
    "label":"4329101 - instalacao de paineis publicitarios",
    "value":"4329101"
  },
  {
    "label":"8550302 - atividades de apoio a educacao, exceto caixas escolares",
    "value":"8550302"
  },
  {
    "label":"1822999 - servicos de acabamentos graficos, exceto encadernacao e plastificacao",
    "value":"1822999"
  },
  {
    "label":"4399101 - administracao de obras",
    "value":"4399101"
  },
  {
    "label":"3103900 - fabricacao de moveis de outros materiais, exceto madeira e metal",
    "value":"3103900"
  },
  {
    "label":"6424703 - cooperativas de credito mutuo",
    "value":"6424703"
  },
  {
    "label":"2829199 - fabricacao de outras maquinas e equipamentos de uso geral nao especificados anteriormente, pecas e acessorios",
    "value":"2829199"
  },
  {
    "label":"8292000 - envasamento e empacotamento sob contrato",
    "value":"8292000"
  },
  {
    "label":"0210107 - extracao de madeira em florestas plantadas",
    "value":"0210107"
  },
  {
    "label":"8430200 - seguridade social obrigatoria",
    "value":"8430200"
  },
  {
    "label":"0162899 - atividades de apoio a pecuaria nao especificadas anteriormente",
    "value":"0162899"
  },
  {
    "label":"8532500 - educacao superior - graduacao e pos-graduacao",
    "value":"8532500"
  },
  {
    "label":"4635402 - comercio atacadista de cerveja, chope e refrigerante",
    "value":"4635402"
  },
  {
    "label":"4634601 - comercio atacadista de carnes bovinas e suinas e derivados",
    "value":"4634601"
  },
  {
    "label":"8640299 - atividades de servicos de complementacao diagnostica e terapeutica nao especificadas anteriormente",
    "value":"8640299"
  },
  {
    "label":"4647801 - comercio atacadista de artigos de escritorio e de papelaria",
    "value":"4647801"
  },
  {
    "label":"2349499 - fabricacao de produtos ceramicos nao-refratarios nao especificados anteriormente",
    "value":"2349499"
  },
  {
    "label":"8592902 - ensino de artes cenicas, exceto danca",
    "value":"8592902"
  },
  {
    "label":"8531700 - educacao superior - graduacao",
    "value":"8531700"
  },
  {
    "label":"0322101 - criacao de peixes em agua doce",
    "value":"0322101"
  },
  {
    "label":"0230600 - atividades de apoio a producao florestal",
    "value":"0230600"
  },
  {
    "label":"5211799 - depositos de mercadorias para terceiros, exceto armazens gerais e guarda-moveis",
    "value":"5211799"
  },
  {
    "label":"0151203 - criacao de bovinos, exceto para corte e leite",
    "value":"0151203"
  },
  {
    "label":"3831999 - recuperacao de materiais metalicos, exceto aluminio",
    "value":"3831999"
  },
  {
    "label":"1610203 - serrarias com desdobramento de madeira em bruto",
    "value":"1610203"
  },
  {
    "label":"4713004 - lojas de departamentos ou magazines, exceto lojas francas (duty free)",
    "value":"4713004"
  },
  {
    "label":"2222600 - fabricacao de embalagens de material plastico",
    "value":"2222600"
  },
  {
    "label":"4637199 - comercio atacadista especializado em outros produtos alimenticios nao especificados anteriormente",
    "value":"4637199"
  },
  {
    "label":"4691500 - comercio atacadista de mercadorias em geral, com predominancia de produtos alimenticios",
    "value":"4691500"
  },
  {
    "label":"4669999 - comercio atacadista de outras maquinas e equipamentos nao especificados anteriormente; partes e pecas",
    "value":"4669999"
  },
  {
    "label":"6491300 - sociedades de fomento mercantil - factoring",
    "value":"6491300"
  },
  {
    "label":"4671100 - comercio atacadista de madeira e produtos derivados",
    "value":"4671100"
  },
  {
    "label":"4644301 - comercio atacadista de medicamentos e drogas de uso humano",
    "value":"4644301"
  },
  {
    "label":"7820500 - locacao de mao-de-obra temporaria",
    "value":"7820500"
  },
  {
    "label":"0133499 - cultivo de frutas de lavoura permanente nao especificadas anteriormente",
    "value":"0133499"
  },
  {
    "label":"4520004 - servicos de alinhamento e balanceamento de veiculos automotores",
    "value":"4520004"
  },
  {
    "label":"3250706 - servicos de protese dentaria",
    "value":"3250706"
  },
  {
    "label":"7312200 - agenciamento de espacos para publicidade, exceto em veiculos de comunicacao",
    "value":"7312200"
  },
  {
    "label":"7740300 - gestao de ativos intangiveis nao-financeiros",
    "value":"7740300"
  },
  {
    "label":"7810800 - selecao e agenciamento de mao-de-obra",
    "value":"7810800"
  },
  {
    "label":"2219600 - fabricacao de artefatos de borracha nao especificados anteriormente",
    "value":"2219600"
  },
  {
    "label":"8650006 - atividades de fonoaudiologia",
    "value":"8650006"
  },
  {
    "label":"5829800 - edicao integrada a impressao de cadastros, listas e de outros produtos graficos",
    "value":"5829800"
  },
  {
    "label":"4672900 - comercio atacadista de ferragens e ferramentas",
    "value":"4672900"
  },
  {
    "label":"1095300 - fabricacao de especiarias, molhos, temperos e condimentos",
    "value":"1095300"
  },
  {
    "label":"7410202 - design de interiores",
    "value":"7410202"
  },
  {
    "label":"1413403 - faccao de roupas profissionais",
    "value":"1413403"
  },
  {
    "label":"9609207 - alojamento de animais domesticos",
    "value":"9609207"
  },
  {
    "label":"0312401 - pesca de peixes em agua doce",
    "value":"0312401"
  },
  {
    "label":"6920602 - atividades de consultoria e auditoria contabil e tributaria",
    "value":"6920602"
  },
  {
    "label":"6630400 - atividades de administracao de fundos por contrato ou comissao",
    "value":"6630400"
  },
  {
    "label":"2062200 - fabricacao de produtos de limpeza e polimento",
    "value":"2062200"
  },
  {
    "label":"9529106 - reparacao de joias",
    "value":"9529106"
  },
  {
    "label":"0116401 - cultivo de amendoim",
    "value":"0116401"
  },
  {
    "label":"8650002 - atividades de profissionais da nutricao",
    "value":"8650002"
  },
  {
    "label":"7733100 - aluguel de maquinas e equipamentos para escritorios",
    "value":"7733100"
  },
  {
    "label":"9329804 - exploracao de jogos eletronicos recreativos",
    "value":"9329804"
  },
  {
    "label":"0119906 - cultivo de mandioca",
    "value":"0119906"
  },
  {
    "label":"4623109 - comercio atacadista de alimentos para animais",
    "value":"4623109"
  },
  {
    "label":"9529101 - reparacao de calcados, bolsas e artigos de viagem",
    "value":"9529101"
  },
  {
    "label":"0122900 - cultivo de flores e plantas ornamentais",
    "value":"0122900"
  },
  {
    "label":"5510803 - moteis",
    "value":"5510803"
  },
  {
    "label":"8660700 - atividades de apoio a gestao de saude",
    "value":"8660700"
  },
  {
    "label":"2950600 - recondicionamento e recuperacao de motores para veiculos automotores",
    "value":"2950600"
  },
  {
    "label":"4530706 - representantes comerciais e agentes do comercio de pecas e acessorios novos e usados para veiculos automotores",
    "value":"4530706"
  },
  {
    "label":"6423900 - caixas economicas",
    "value":"6423900"
  },
  {
    "label":"3600601 - captacao, tratamento e distribuicao de agua",
    "value":"3600601"
  },
  {
    "label":"4649408 - comercio atacadista de produtos de higiene, limpeza e conservacao domiciliar",
    "value":"4649408"
  },
  {
    "label":"2063100 - fabricacao de cosmeticos, produtos de perfumaria e de higiene pessoal",
    "value":"2063100"
  },
  {
    "label":"3600602 - distribuicao de agua por caminhoes",
    "value":"3600602"
  },
  {
    "label":"4329199 - outras obras de instalacoes em construcoes nao especificadas anteriormente",
    "value":"4329199"
  },
  {
    "label":"6421200 - bancos comerciais",
    "value":"6421200"
  },
  {
    "label":"3314712 - manutencao e reparacao de tratores agricolas",
    "value":"3314712"
  },
  {
    "label":"4684299 - comercio atacadista de outros produtos quimicos e petroquimicos nao especificados anteriormente",
    "value":"4684299"
  },
  {
    "label":"6550200 - planos de saude",
    "value":"6550200"
  },
  {
    "label":"3291400 - fabricacao de escovas, pinceis e vassouras",
    "value":"3291400"
  },
  {
    "label":"4329105 - tratamentos termicos, acusticos ou de vibracao",
    "value":"4329105"
  },
  {
    "label":"1413401 - confeccao de roupas profissionais, exceto sob medida",
    "value":"1413401"
  },
  {
    "label":"3299004 - fabricacao de paineis e letreiros luminosos",
    "value":"3299004"
  },
  {
    "label":"7319004 - consultoria em publicidade",
    "value":"7319004"
  },
  {
    "label":"4921302 - transporte rodoviario coletivo de passageiros, com itinerario fixo, intermunicipal em regiao metropolitana",
    "value":"4921302"
  },
  {
    "label":"1411802 - faccao de roupas intimas",
    "value":"1411802"
  },
  {
    "label":"7490105 - agenciamento de profissionais para atividades esportivas, culturais e artisticas",
    "value":"7490105"
  },
  {
    "label":"3102100 - fabricacao de moveis com predominancia de metal",
    "value":"3102100"
  },
  {
    "label":"0116499 - cultivo de outras oleaginosas de lavoura temporaria nao especificadas anteriormente",
    "value":"0116499"
  },
  {
    "label":"2399101 - decoracao, lapidacao, gravacao, vitrificacao e outros trabalhos em ceramica, louca, vidro e cristal",
    "value":"2399101"
  },
  {
    "label":"3702900 - atividades relacionadas a esgoto, exceto a gestao de redes",
    "value":"3702900"
  },
  {
    "label":"3514000 - distribuicao de energia eletrica",
    "value":"3514000"
  },
  {
    "label":"8640207 - servicos de diagnostico por imagem sem uso de radiacao ionizante, exceto ressonancia magnetica",
    "value":"8640207"
  },
  {
    "label":"3211602 - fabricacao de artefatos de joalheria e ourivesaria",
    "value":"3211602"
  },
  {
    "label":"1099604 - fabricacao de gelo comum",
    "value":"1099604"
  },
  {
    "label":"4292802 - obras de montagem industrial",
    "value":"4292802"
  },
  {
    "label":"1629302 - fabricacao de artefatos diversos de cortica, bambu, palha, vime e outros materiais trancados, exceto moveis",
    "value":"1629302"
  },
  {
    "label":"8690901 - atividades de praticas integrativas e complementares em saude humana",
    "value":"8690901"
  },
  {
    "label":"7119704 - servicos de pericia tecnica relacionados a seguranca do trabalho",
    "value":"7119704"
  },
  {
    "label":"4631100 - comercio atacadista de leite e laticinios",
    "value":"4631100"
  },
  {
    "label":"4641901 - comercio atacadista de tecidos",
    "value":"4641901"
  },
  {
    "label":"1414200 - fabricacao de acessorios do vestuario, exceto para seguranca e protecao",
    "value":"1414200"
  },
  {
    "label":"6499901 - clubes de investimento",
    "value":"6499901"
  },
  {
    "label":"3314720 - manutencao e reparacao de maquinas e equipamentos para a industria textil, do vestuario, do couro e calcados",
    "value":"3314720"
  },
  {
    "label":"0159801 - apicultura",
    "value":"0159801"
  },
  {
    "label":"4623106 - comercio atacadista de sementes, flores, plantas e gramas",
    "value":"4623106"
  },
  {
    "label":"4687701 - comercio atacadista de residuos de papel e papelao",
    "value":"4687701"
  },
  {
    "label":"0210108 - producao de carvao vegetal - florestas plantadas",
    "value":"0210108"
  },
  {
    "label":"0152102 - criacao de equinos",
    "value":"0152102"
  },
  {
    "label":"2539002 - servicos de tratamento e revestimento em metais",
    "value":"2539002"
  },
  {
    "label":"1012101 - abate de aves",
    "value":"1012101"
  },
  {
    "label":"1731100 - fabricacao de embalagens de papel",
    "value":"1731100"
  },
  {
    "label":"1822901 - servicos de encadernacao e plastificacao",
    "value":"1822901"
  },
  {
    "label":"0810099 - extracao e britamento de pedras e outros materiais para construcao e beneficiamento associado",
    "value":"0810099"
  },
  {
    "label":"2833000 - fabricacao de maquinas e equipamentos para a agricultura e pecuaria, pecas e acessorios, exceto para irrigacao",
    "value":"2833000"
  },
  {
    "label":"9529103 - reparacao de relogios",
    "value":"9529103"
  },
  {
    "label":"4623199 - comercio atacadista de materias-primas agricolas nao especificadas anteriormente",
    "value":"4623199"
  },
  {
    "label":"4665600 - comercio atacadista de maquinas e equipamentos para uso comercial; partes e pecas",
    "value":"4665600"
  },
  {
    "label":"4635401 - comercio atacadista de agua mineral",
    "value":"4635401"
  },
  {
    "label":"0119909 - cultivo de tomate rasteiro",
    "value":"0119909"
  },
  {
    "label":"5920100 - atividades de gravacao de som e de edicao de musica",
    "value":"5920100"
  },
  {
    "label":"1540800 - fabricacao de partes para calcados, de qualquer material",
    "value":"1540800"
  },
  {
    "label":"4751202 - recarga de cartuchos para equipamentos de informatica",
    "value":"4751202"
  },
  {
    "label":"8020002 - outras atividades de servicos de seguranca",
    "value":"8020002"
  },
  {
    "label":"9329801 - discotecas, danceterias, saloes de danca e similares",
    "value":"9329801"
  },
  {
    "label":"3311200 - manutencao e reparacao de tanques, reservatorios metalicos e caldeiras, exceto para veiculos",
    "value":"3311200"
  },
  {
    "label":"4512902 - comercio sob consignacao de veiculos automotores",
    "value":"4512902"
  },
  {
    "label":"1622602 - fabricacao de esquadrias de madeira e de pecas de madeira para instalacoes industriais e comerciais",
    "value":"1622602"
  },
  {
    "label":"4744006 - comercio varejista de pedras para revestimento",
    "value":"4744006"
  },
  {
    "label":"8650001 - atividades de enfermagem",
    "value":"8650001"
  },
  {
    "label":"4635499 - comercio atacadista de bebidas nao especificadas anteriormente",
    "value":"4635499"
  },
  {
    "label":"6391700 - agencias de noticias",
    "value":"6391700"
  },
  {
    "label":"0161001 - servico de pulverizacao e controle de pragas agricolas",
    "value":"0161001"
  },
  {
    "label":"4391600 - obras de fundacoes",
    "value":"4391600"
  },
  {
    "label":"8730199 - atividades de assistencia social prestadas em residencias coletivas e particulares nao especificadas anteriormente",
    "value":"8730199"
  },
  {
    "label":"4634603 - comercio atacadista de pescados e frutos do mar",
    "value":"4634603"
  },
  {
    "label":"5240199 - atividades auxiliares dos transportes aereos, exceto operacao dos aeroportos e campos de aterrissagem",
    "value":"5240199"
  },
  {
    "label":"4664800 - comercio atacadista de maquinas, aparelhos e equipamentos para uso odonto-medico-hospitalar; partes e pecas",
    "value":"4664800"
  },
  {
    "label":"4785701 - comercio varejista de antiguidades",
    "value":"4785701"
  },
  {
    "label":"4689301 - comercio atacadista de produtos da extracao mineral, exceto combustiveis",
    "value":"4689301"
  },
  {
    "label":"4649402 - comercio atacadista de aparelhos eletronicos de uso pessoal e domestico",
    "value":"4649402"
  },
  {
    "label":"4641903 - comercio atacadista de artigos de armarinho",
    "value":"4641903"
  },
  {
    "label":"2212900 - reforma de pneumaticos usados",
    "value":"2212900"
  },
  {
    "label":"2099199 - fabricacao de outros produtos quimicos nao especificados anteriormente",
    "value":"2099199"
  },
  {
    "label":"4541204 - comercio a varejo de motocicletas e motonetas usadas",
    "value":"4541204"
  },
  {
    "label":"6612605 - agentes de investimentos em aplicacoes financeiras",
    "value":"6612605"
  },
  {
    "label":"8592901 - ensino de danca",
    "value":"8592901"
  },
  {
    "label":"4692300 - comercio atacadista de mercadorias em geral, com predominancia de insumos agropecuarios",
    "value":"4692300"
  },
  {
    "label":"2229302 - fabricacao de artefatos de material plastico para usos industriais",
    "value":"2229302"
  },
  {
    "label":"1623400 - fabricacao de artefatos de tanoaria e de embalagens de madeira",
    "value":"1623400"
  },
  {
    "label":"4221902 - construcao de estacoes e redes de distribuicao de energia eletrica",
    "value":"4221902"
  },
  {
    "label":"1033302 - fabricacao de sucos de frutas, hortalicas e legumes, exceto concentrados",
    "value":"1033302"
  },
  {
    "label":"2319200 - fabricacao de artigos de vidro",
    "value":"2319200"
  },
  {
    "label":"0119903 - cultivo de batata-inglesa",
    "value":"0119903"
  },
  {
    "label":"7420002 - atividades de producao de fotografias aereas e submarinas",
    "value":"7420002"
  },
  {
    "label":"7729201 - aluguel de aparelhos de jogos eletronicos",
    "value":"7729201"
  },
  {
    "label":"0810002 - extracao de granito e beneficiamento associado",
    "value":"0810002"
  },
  {
    "label":"6629100 - atividades auxiliares dos seguros, da previdencia complementar e dos planos de saude nao especificadas anteriormente",
    "value":"6629100"
  },
  {
    "label":"4642702 - comercio atacadista de roupas e acessorios para uso profissional e de seguranca do trabalho",
    "value":"4642702"
  },
  {
    "label":"3240099 - fabricacao de outros brinquedos e jogos recreativos nao especificados anteriormente",
    "value":"3240099"
  },
  {
    "label":"8640201 - laboratorios de anatomia patologica e citologica",
    "value":"8640201"
  },
  {
    "label":"7320300 - pesquisas de mercado e de opiniao publica",
    "value":"7320300"
  },
  {
    "label":"6110801 - servicos de telefonia fixa comutada - stfc",
    "value":"6110801"
  },
  {
    "label":"0161002 - servico de poda de arvores para lavouras",
    "value":"0161002"
  },
  {
    "label":"2949299 - fabricacao de outras pecas e acessorios para veiculos automotores nao especificadas anteriormente",
    "value":"2949299"
  },
  {
    "label":"2330305 - preparacao de massa de concreto e argamassa para construcao",
    "value":"2330305"
  },
  {
    "label":"1011201 - frigorifico - abate de bovinos",
    "value":"1011201"
  },
  {
    "label":"5250803 - agenciamento de cargas, exceto para o transporte maritimo",
    "value":"5250803"
  },
  {
    "label":"7732202 - aluguel de andaimes",
    "value":"7732202"
  },
  {
    "label":"4643501 - comercio atacadista de calcados",
    "value":"4643501"
  },
  {
    "label":"4679604 - comercio atacadista especializado de materiais de construcao nao especificados anteriormente",
    "value":"4679604"
  },
  {
    "label":"3314713 - manutencao e reparacao de maquinas-ferramenta",
    "value":"3314713"
  },
  {
    "label":"1063500 - fabricacao de farinha de mandioca e derivados",
    "value":"1063500"
  },
  {
    "label":"4685100 - comercio atacadista de produtos siderurgicos e metalurgicos, exceto para construcao",
    "value":"4685100"
  },
  {
    "label":"5250804 - organizacao logistica do transporte de carga",
    "value":"5250804"
  },
  {
    "label":"9311500 - gestao de instalacoes de esportes",
    "value":"9311500"
  },
  {
    "label":"0139399 - cultivo de outras plantas de lavoura permanente nao especificadas anteriormente",
    "value":"0139399"
  },
  {
    "label":"4679602 - comercio atacadista de marmores e granitos",
    "value":"4679602"
  },
  {
    "label":"1093702 - fabricacao de frutas cristalizadas, balas e semelhantes",
    "value":"1093702"
  },
  {
    "label":"2061400 - fabricacao de saboes e detergentes sinteticos",
    "value":"2061400"
  },
  {
    "label":"1113502 - fabricacao de cervejas e chopes",
    "value":"1113502"
  },
  {
    "label":"5250802 - atividades de despachantes aduaneiros",
    "value":"5250802"
  },
  {
    "label":"1033301 - fabricacao de sucos concentrados de frutas, hortalicas e legumes",
    "value":"1033301"
  },
  {
    "label":"6021700 - atividades de televisao aberta",
    "value":"6021700"
  },
  {
    "label":"4511103 - comercio por atacado de automoveis, camionetas e utilitarios novos e usados",
    "value":"4511103"
  },
  {
    "label":"3312102 - manutencao e reparacao de aparelhos e instrumentos de medida, teste e controle",
    "value":"3312102"
  },
  {
    "label":"4922102 - transporte rodoviario coletivo de passageiros, com itinerario fixo, interestadual",
    "value":"4922102"
  },
  {
    "label":"2593400 - fabricacao de artigos de metal para uso domestico e pessoal",
    "value":"2593400"
  },
  {
    "label":"1081302 - torrefacao e moagem de cafe",
    "value":"1081302"
  },
  {
    "label":"5822101 - edicao integrada a impressao de jornais diarios",
    "value":"5822101"
  },
  {
    "label":"5099801 - transporte aquaviario para passeios turisticos",
    "value":"5099801"
  },
  {
    "label":"4649410 - comercio atacadista de joias, relogios e bijuterias, inclusive pedras preciosas e semipreciosas lapidadas",
    "value":"4649410"
  },
  {
    "label":"0119905 - cultivo de feijao",
    "value":"0119905"
  },
  {
    "label":"0155505 - producao de ovos",
    "value":"0155505"
  },
  {
    "label":"4649404 - comercio atacadista de moveis e artigos de colchoaria",
    "value":"4649404"
  },
  {
    "label":"3317102 - manutencao e reparacao de embarcacoes para esporte e lazer",
    "value":"3317102"
  },
  {
    "label":"2229301 - fabricacao de artefatos de material plastico para uso pessoal e domestico",
    "value":"2229301"
  },
  {
    "label":"4330401 - impermeabilizacao em obras de engenharia civil",
    "value":"4330401"
  },
  {
    "label":"7119702 - atividades de estudos geologicos",
    "value":"7119702"
  },
  {
    "label":"3230200 - fabricacao de artefatos para pesca e esporte",
    "value":"3230200"
  },
  {
    "label":"2740602 - fabricacao de luminarias e outros equipamentos de iluminacao",
    "value":"2740602"
  },
  {
    "label":"0311601 - pesca de peixes em agua salgada",
    "value":"0311601"
  },
  {
    "label":"2610800 - fabricacao de componentes eletronicos",
    "value":"2610800"
  },
  {
    "label":"0133410 - cultivo de manga",
    "value":"0133410"
  },
  {
    "label":"3314701 - manutencao e reparacao de maquinas motrizes nao-eletricas",
    "value":"3314701"
  },
  {
    "label":"2071100 - fabricacao de tintas, vernizes, esmaltes e lacas",
    "value":"2071100"
  },
  {
    "label":"4637101 - comercio atacadista de cafe torrado, moido e soluvel",
    "value":"4637101"
  },
  {
    "label":"1061901 - beneficiamento de arroz",
    "value":"1061901"
  },
  {
    "label":"7739002 - aluguel de equipamentos cientificos, medicos e hospitalares, sem operador",
    "value":"7739002"
  },
  {
    "label":"9601703 - toalheiros",
    "value":"9601703"
  },
  {
    "label":"3292202 - fabricacao de equipamentos e acessorios para seguranca pessoal e profissional",
    "value":"3292202"
  },
  {
    "label":"0119904 - cultivo de cebola",
    "value":"0119904"
  },
  {
    "label":"5590601 - albergues, exceto assistenciais",
    "value":"5590601"
  },
  {
    "label":"6470102 - fundos de investimento previdenciarios",
    "value":"6470102"
  },
  {
    "label":"0154700 - criacao de suinos",
    "value":"0154700"
  },
  {
    "label":"2391502 - aparelhamento de pedras para construcao, exceto associado a extracao",
    "value":"2391502"
  },
  {
    "label":"3312103 - manutencao e reparacao de aparelhos eletromedicos e eletroterapeuticos e equipamentos de irradiacao",
    "value":"3312103"
  },
  {
    "label":"4645103 - comercio atacadista de produtos odontologicos",
    "value":"4645103"
  },
  {
    "label":"4681801 - comercio atacadista de alcool carburante, biodiesel, gasolina e demais derivados de petroleo, exceto lubrificantes, nao realizado por transportador retalhista (t.r.r.)",
    "value":"4681801"
  },
  {
    "label":"9002702 - restauracao de obras-de-arte",
    "value":"9002702"
  },
  {
    "label":"1121600 - fabricacao de aguas envasadas",
    "value":"1121600"
  },
  {
    "label":"5099899 - outros transportes aquaviarios nao especificados anteriormente",
    "value":"5099899"
  },
  {
    "label":"1111901 - fabricacao de aguardente de cana-de-acucar",
    "value":"1111901"
  },
  {
    "label":"4530702 - comercio por atacado de pneumaticos e camaras-de-ar",
    "value":"4530702"
  },
  {
    "label":"2840200 - fabricacao de maquinas-ferramenta, pecas e acessorios",
    "value":"2840200"
  },
  {
    "label":"4929903 - organizacao de excursoes em veiculos rodoviarios proprios, municipal",
    "value":"4929903"
  },
  {
    "label":"6499999 - outras atividades de servicos financeiros nao especificadas anteriormente",
    "value":"6499999"
  },
  {
    "label":"4647802 - comercio atacadista de livros, jornais e outras publicacoes",
    "value":"4647802"
  },
  {
    "label":"4622200 - comercio atacadista de soja",
    "value":"4622200"
  },
  {
    "label":"2930101 - fabricacao de cabines, carrocerias e reboques para caminhoes",
    "value":"2930101"
  },
  {
    "label":"4399104 - servicos de operacao e fornecimento de equipamentos para transporte e elevacao de cargas e pessoas para uso em obras",
    "value":"4399104"
  },
  {
    "label":"4621400 - comercio atacadista de cafe em grao",
    "value":"4621400"
  },
  {
    "label":"3314706 - manutencao e reparacao de maquinas, aparelhos e equipamentos para instalacoes termicas",
    "value":"3314706"
  },
  {
    "label":"1811302 - impressao de livros, revistas e outras publicacoes periodicas",
    "value":"1811302"
  },
  {
    "label":"6120501 - telefonia movel celular",
    "value":"6120501"
  },
  {
    "label":"7420003 - laboratorios fotograficos",
    "value":"7420003"
  },
  {
    "label":"4637107 - comercio atacadista de chocolates, confeitos, balas, bombons e semelhantes",
    "value":"4637107"
  },
  {
    "label":"5310502 - atividades de franqueadas do correio nacional",
    "value":"5310502"
  },
  {
    "label":"0111301 - cultivo de arroz",
    "value":"0111301"
  },
  {
    "label":"2823200 - fabricacao de maquinas e aparelhos de refrigeracao e ventilacao para uso industrial e comercial, pecas e acessorios",
    "value":"2823200"
  },
  {
    "label":"1051100 - preparacao do leite",
    "value":"1051100"
  },
  {
    "label":"3299006 - fabricacao de velas, inclusive decorativas",
    "value":"3299006"
  },
  {
    "label":"4623102 - comercio atacadista de couros, las, peles e outros subprodutos nao-comestiveis de origem animal",
    "value":"4623102"
  },
  {
    "label":"1733800 - fabricacao de chapas e de embalagens de papelao ondulado",
    "value":"1733800"
  },
  {
    "label":"8650005 - atividades de terapia ocupacional",
    "value":"8650005"
  },
  {
    "label":"4399102 - montagem e desmontagem de andaimes e outras estruturas temporarias",
    "value":"4399102"
  },
  {
    "label":"4674500 - comercio atacadista de cimento",
    "value":"4674500"
  },
  {
    "label":"4646002 - comercio atacadista de produtos de higiene pessoal",
    "value":"4646002"
  },
  {
    "label":"1071600 - fabricacao de acucar em bruto",
    "value":"1071600"
  },
  {
    "label":"1533500 - fabricacao de calcados de material sintetico",
    "value":"1533500"
  },
  {
    "label":"4662100 - comercio atacadista de maquinas, equipamentos para terraplenagem, mineracao e construcao; partes e pecas",
    "value":"4662100"
  },
  {
    "label":"0142300 - producao de mudas e outras formas de propagacao vegetal, certificadas",
    "value":"0142300"
  },
  {
    "label":"5590602 - campings",
    "value":"5590602"
  },
  {
    "label":"1064300 - fabricacao de farinha de milho e derivados, exceto oleos de milho",
    "value":"1064300"
  },
  {
    "label":"5911101 - estudios cinematograficos",
    "value":"5911101"
  },
  {
    "label":"4221905 - manutencao de estacoes e redes de telecomunicacoes",
    "value":"4221905"
  },
  {
    "label":"0210103 - cultivo de pinus",
    "value":"0210103"
  },
  {
    "label":"1621800 - fabricacao de madeira laminada e de chapas de madeira compensada, prensada e aglomerada",
    "value":"1621800"
  },
  {
    "label":"5232000 - atividades de agenciamento maritimo",
    "value":"5232000"
  },
  {
    "label":"5211702 - guarda-moveis",
    "value":"5211702"
  },
  {
    "label":"4681803 - comercio atacadista de combustiveis de origem vegetal, exceto alcool carburante",
    "value":"4681803"
  },
  {
    "label":"4221904 - construcao de estacoes e redes de telecomunicacoes",
    "value":"4221904"
  },
  {
    "label":"7410299 - atividades de design nao especificadas anteriormente",
    "value":"7410299"
  },
  {
    "label":"2790299 - fabricacao de outros equipamentos e aparelhos eletricos nao especificados anteriormente",
    "value":"2790299"
  },
  {
    "label":"4763605 - comercio varejista de embarcacoes e outros veiculos recreativos; pecas e acessorios",
    "value":"4763605"
  },
  {
    "label":"2651500 - fabricacao de aparelhos e equipamentos de medida, teste e controle",
    "value":"2651500"
  },
  {
    "label":"8011102 - servicos de adestramento de caes de guarda",
    "value":"8011102"
  },
  {
    "label":"9102302 - restauracao e conservacao de lugares e predios historicos",
    "value":"9102302"
  },
  {
    "label":"8422100 - defesa",
    "value":"8422100"
  },
  {
    "label":"7830200 - fornecimento e gestao de recursos humanos para terceiros",
    "value":"7830200"
  },
  {
    "label":"4637104 - comercio atacadista de paes, bolos, biscoitos e similares",
    "value":"4637104"
  },
  {
    "label":"0111399 - cultivo de outros cereais nao especificados anteriormente",
    "value":"0111399"
  },
  {
    "label":"2229303 - fabricacao de artefatos de material plastico para uso na construcao, exceto tubos e acessorios",
    "value":"2229303"
  },
  {
    "label":"6201502 - web design",
    "value":"6201502"
  },
  {
    "label":"3512300 - transmissao de energia eletrica",
    "value":"3512300"
  },
  {
    "label":"5510802 - apart-hoteis",
    "value":"5510802"
  },
  {
    "label":"9002701 - atividades de artistas plasticos, jornalistas independentes e escritores",
    "value":"9002701"
  },
  {
    "label":"6493000 - administracao de consorcios para aquisicao de bens e direitos",
    "value":"6493000"
  },
  {
    "label":"9321200 - parques de diversao e parques tematicos",
    "value":"9321200"
  },
  {
    "label":"4221903 - manutencao de redes de distribuicao de energia eletrica",
    "value":"4221903"
  },
  {
    "label":"1330800 - fabricacao de tecidos de malha",
    "value":"1330800"
  },
  {
    "label":"4929904 - organizacao de excursoes em veiculos rodoviarios proprios, intermunicipal, interestadual e internacional",
    "value":"4929904"
  },
  {
    "label":"5912001 - servicos de dublagem",
    "value":"5912001"
  },
  {
    "label":"8425600 - defesa civil",
    "value":"8425600"
  },
  {
    "label":"4635403 - comercio atacadista de bebidas com atividade de fracionamento e acondicionamento associada",
    "value":"4635403"
  },
  {
    "label":"4637106 - comercio atacadista de sorvetes",
    "value":"4637106"
  },
  {
    "label":"2451200 - fundicao de ferro e aco",
    "value":"2451200"
  },
  {
    "label":"4541202 - comercio por atacado de pecas e acessorios para motocicletas e motonetas",
    "value":"4541202"
  },
  {
    "label":"6911702 - atividades auxiliares da justica",
    "value":"6911702"
  },
  {
    "label":"5821200 - edicao integrada a impressao de livros",
    "value":"5821200"
  },
  {
    "label":"4632002 - comercio atacadista de farinhas, amidos e feculas",
    "value":"4632002"
  },
  {
    "label":"4623101 - comercio atacadista de animais vivos",
    "value":"4623101"
  },
  {
    "label":"0322104 - criacao de peixes ornamentais em agua doce",
    "value":"0322104"
  },
  {
    "label":"0899199 - extracao de outros minerais nao-metalicos nao especificados anteriormente",
    "value":"0899199"
  },
  {
    "label":"2592602 - fabricacao de produtos de trefilados de metal, exceto padronizados",
    "value":"2592602"
  },
  {
    "label":"2731700 - fabricacao de aparelhos e equipamentos para distribuicao e controle de energia eletrica",
    "value":"2731700"
  },
  {
    "label":"2399199 - fabricacao de outros produtos de minerais nao-metalicos nao especificados anteriormente",
    "value":"2399199"
  },
  {
    "label":"8424800 - seguranca e ordem publica",
    "value":"8424800"
  },
  {
    "label":"8533300 - educacao superior - pos-graduacao e extensao",
    "value":"8533300"
  },
  {
    "label":"0163600 - atividades de pos-colheita",
    "value":"0163600"
  },
  {
    "label":"4681805 - comercio atacadista de lubrificantes",
    "value":"4681805"
  },
  {
    "label":"0141502 - producao de sementes certificadas de forrageiras para formacao de pasto",
    "value":"0141502"
  },
  {
    "label":"2862300 - fabricacao de maquinas e equipamentos para as industrias de alimentos, bebidas e fumo, pecas e acessorios",
    "value":"2862300"
  },
  {
    "label":"5250801 - comissaria de despachos",
    "value":"5250801"
  },
  {
    "label":"1510600 - curtimento e outras preparacoes de couro",
    "value":"1510600"
  },
  {
    "label":"9603399 - atividades funerarias e servicos relacionados nao especificados anteriormente",
    "value":"9603399"
  },
  {
    "label":"2513600 - fabricacao de obras de caldeiraria pesada",
    "value":"2513600"
  },
  {
    "label":"3812200 - coleta de residuos perigosos",
    "value":"3812200"
  },
  {
    "label":"1742701 - fabricacao de fraldas descartaveis",
    "value":"1742701"
  },
  {
    "label":"2599302 - servico de corte e dobra de metais",
    "value":"2599302"
  },
  {
    "label":"1322700 - tecelagem de fios de fibras texteis naturais, exceto algodao",
    "value":"1322700"
  },
  {
    "label":"8711501 - clinicas e residencias geriatricas",
    "value":"8711501"
  },
  {
    "label":"0210106 - cultivo de mudas em viveiros florestais",
    "value":"0210106"
  },
  {
    "label":"2052500 - fabricacao de desinfestantes domissanitarios",
    "value":"2052500"
  },
  {
    "label":"2521700 - fabricacao de tanques, reservatorios metalicos e caldeiras para aquecimento central",
    "value":"2521700"
  },
  {
    "label":"8640208 - servicos de diagnostico por registro grafico - ecg, eeg e outros exames analogos",
    "value":"8640208"
  },
  {
    "label":"2093200 - fabricacao de aditivos de uso industrial",
    "value":"2093200"
  },
  {
    "label":"5021101 - transporte por navegacao interior de carga, municipal, exceto travessia",
    "value":"5021101"
  },
  {
    "label":"8630506 - servicos de vacinacao e imunizacao humana",
    "value":"8630506"
  },
  {
    "label":"4636202 - comercio atacadista de cigarros, cigarrilhas e charutos",
    "value":"4636202"
  },
  {
    "label":"0893200 - extracao de gemas (pedras preciosas e semipreciosas)",
    "value":"0893200"
  },
  {
    "label":"7490102 - escafandria e mergulho",
    "value":"7490102"
  },
  {
    "label":"0600001 - extracao de petroleo e gas natural",
    "value":"0600001"
  },
  {
    "label":"1321900 - tecelagem de fios de algodao",
    "value":"1321900"
  },
  {
    "label":"5111100 - transporte aereo de passageiros regular",
    "value":"5111100"
  },
  {
    "label":"6621501 - peritos e avaliadores de seguros",
    "value":"6621501"
  },
  {
    "label":"0119908 - cultivo de melancia",
    "value":"0119908"
  },
  {
    "label":"3220500 - fabricacao de instrumentos musicais, pecas e acessorios",
    "value":"3220500"
  },
  {
    "label":"4689302 - comercio atacadista de fios e fibras beneficiados",
    "value":"4689302"
  },
  {
    "label":"4312600 - perfuracoes e sondagens",
    "value":"4312600"
  },
  {
    "label":"4641902 - comercio atacadista de artigos de cama, mesa e banho",
    "value":"4641902"
  },
  {
    "label":"3314718 - manutencao e reparacao de maquinas para a industria metalurgica, exceto maquinas-ferramenta",
    "value":"3314718"
  },
  {
    "label":"6492100 - securitizacao de creditos",
    "value":"6492100"
  },
  {
    "label":"4679601 - comercio atacadista de tintas, vernizes e similares",
    "value":"4679601"
  },
  {
    "label":"2391501 - britamento de pedras, exceto associado a extracao",
    "value":"2391501"
  },
  {
    "label":"4623108 - comercio atacadista de materias-primas agricolas com atividade de fracionamento e acondicionamento associada",
    "value":"4623108"
  },
  {
    "label":"1354500 - fabricacao de tecidos especiais, inclusive artefatos",
    "value":"1354500"
  },
  {
    "label":"0153902 - criacao de ovinos, inclusive para producao de la",
    "value":"0153902"
  },
  {
    "label":"0133409 - cultivo de maracuja",
    "value":"0133409"
  },
  {
    "label":"4644302 - comercio atacadista de medicamentos e drogas de uso veterinario",
    "value":"4644302"
  },
  {
    "label":"1732000 - fabricacao de embalagens de cartolina e papel-cartao",
    "value":"1732000"
  },
  {
    "label":"5913800 - distribuicao cinematografica, de video e de programas de televisao",
    "value":"5913800"
  },
  {
    "label":"4511104 - comercio por atacado de caminhoes novos e usados",
    "value":"4511104"
  },
  {
    "label":"4311802 - preparacao de canteiro e limpeza de terreno",
    "value":"4311802"
  },
  {
    "label":"9329803 - exploracao de jogos de sinuca, bilhar e similares",
    "value":"9329803"
  },
  {
    "label":"2392300 - fabricacao de cal e gesso",
    "value":"2392300"
  },
  {
    "label":"9003500 - gestao de espacos para artes cenicas, espetaculos e outras atividades artisticas",
    "value":"9003500"
  },
  {
    "label":"6424704 - cooperativas de credito rural",
    "value":"6424704"
  },
  {
    "label":"1610202 - serrarias sem desdobramento de madeira",
    "value":"1610202"
  },
  {
    "label":"3299002 - fabricacao de canetas, lapis e outros artigos para escritorio",
    "value":"3299002"
  },
  {
    "label":"6511101 - sociedade seguradora de seguros vida",
    "value":"6511101"
  },
  {
    "label":"4682600 - comercio atacadista de gas liquefeito de petroleo (glp)",
    "value":"4682600"
  },
  {
    "label":"1099605 - fabricacao de produtos para infusao (cha, mate, etc.)",
    "value":"1099605"
  },
  {
    "label":"0724301 - extracao de minerio de metais preciosos",
    "value":"0724301"
  },
  {
    "label":"1112700 - fabricacao de vinho",
    "value":"1112700"
  },
  {
    "label":"3317101 - manutencao e reparacao de embarcacoes e estruturas flutuantes",
    "value":"3317101"
  },
  {
    "label":"1065101 - fabricacao de amidos e feculas de vegetais",
    "value":"1065101"
  },
  {
    "label":"8640203 - servicos de dialise e nefrologia",
    "value":"8640203"
  },
  {
    "label":"1311100 - preparacao e fiacao de fibras de algodao",
    "value":"1311100"
  },
  {
    "label":"9200301 - casas de bingo",
    "value":"9200301"
  },
  {
    "label":"2861500 - fabricacao de maquinas para a industria metalurgica, pecas e acessorios, exceto maquinas-ferramenta",
    "value":"2861500"
  },
  {
    "label":"7410203 - design de produto",
    "value":"7410203"
  },
  {
    "label":"2822402 - fabricacao de maquinas, equipamentos e aparelhos para transporte e elevacao de cargas, pecas e acessorios",
    "value":"2822402"
  },
  {
    "label":"7729299 - aluguel de outros objetos pessoais e domesticos nao especificados anteriormente",
    "value":"7729299"
  },
  {
    "label":"4542101 - representantes comerciais e agentes do comercio de motocicletas e motonetas, pecas e acessorios",
    "value":"4542101"
  },
  {
    "label":"8640204 - servicos de tomografia",
    "value":"8640204"
  },
  {
    "label":"5091201 - transporte por navegacao de travessia, municipal",
    "value":"5091201"
  },
  {
    "label":"8622400 - servicos de remocao de pacientes, exceto os servicos moveis de atendimento a urgencias",
    "value":"8622400"
  },
  {
    "label":"2813500 - fabricacao de valvulas, registros e dispositivos semelhantes, pecas e acessorios",
    "value":"2813500"
  },
  {
    "label":"0112199 - cultivo de outras fibras de lavoura temporaria nao especificadas anteriormente",
    "value":"0112199"
  },
  {
    "label":"4634602 - comercio atacadista de aves abatidas e derivados",
    "value":"4634602"
  },
  {
    "label":"0152101 - criacao de bufalinos",
    "value":"0152101"
  },
  {
    "label":"6436100 - sociedades de credito, financiamento e investimento - financeiras",
    "value":"6436100"
  },
  {
    "label":"2710402 - fabricacao de transformadores, indutores, conversores, sincronizadores e semelhantes, pecas e acessorios",
    "value":"2710402"
  },
  {
    "label":"1742799 - fabricacao de produtos de papel para uso domestico e higienico-sanitario nao especificados anteriormente",
    "value":"1742799"
  },
  {
    "label":"5011401 - transporte maritimo de cabotagem - carga",
    "value":"5011401"
  },
  {
    "label":"4679603 - comercio atacadista de vidros, espelhos, vitrais e molduras",
    "value":"4679603"
  },
  {
    "label":"3250701 - fabricacao de instrumentos nao-eletronicos e utensilios para uso medico, cirurgico, odontologico e de laboratorio",
    "value":"3250701"
  },
  {
    "label":"3250705 - fabricacao de materiais para medicina e odontologia",
    "value":"3250705"
  },
  {
    "label":"2812700 - fabricacao de equipamentos hidraulicos e pneumaticos, pecas e acessorios, exceto valvulas",
    "value":"2812700"
  },
  {
    "label":"0162803 - servico de manejo de animais",
    "value":"0162803"
  },
  {
    "label":"4686901 - comercio atacadista de papel e papelao em bruto",
    "value":"4686901"
  },
  {
    "label":"5239799 - atividades auxiliares dos transportes aquaviarios nao especificadas anteriormente",
    "value":"5239799"
  },
  {
    "label":"9001904 - producao de espetaculos circenses, de marionetes e similares",
    "value":"9001904"
  },
  {
    "label":"5222200 - terminais rodoviarios e ferroviarios",
    "value":"5222200"
  },
  {
    "label":"2449199 - metalurgia de outros metais nao-ferrosos e suas ligas nao especificados anteriormente",
    "value":"2449199"
  },
  {
    "label":"5823900 - edicao integrada a impressao de revistas",
    "value":"5823900"
  },
  {
    "label":"1041400 - fabricacao de oleos vegetais em bruto, exceto oleo de milho",
    "value":"1041400"
  },
  {
    "label":"1812100 - impressao de material de seguranca",
    "value":"1812100"
  },
  {
    "label":"2029100 - fabricacao de produtos quimicos organicos nao especificados anteriormente",
    "value":"2029100"
  },
  {
    "label":"4684201 - comercio atacadista de resinas e elastomeros",
    "value":"4684201"
  },
  {
    "label":"9001905 - producao de espetaculos de rodeios, vaquejadas e similares",
    "value":"9001905"
  },
  {
    "label":"2640000 - fabricacao de aparelhos de recepcao, reproducao, gravacao e amplificacao de audio e video",
    "value":"2640000"
  },
  {
    "label":"8299702 - emissao de vales-alimentacao, vales-transporte e similares",
    "value":"8299702"
  },
  {
    "label":"1020102 - fabricacao de conservas de peixes, crustaceos e moluscos",
    "value":"1020102"
  },
  {
    "label":"2341900 - fabricacao de produtos ceramicos refratarios",
    "value":"2341900"
  },
  {
    "label":"1353700 - fabricacao de artefatos de cordoaria",
    "value":"1353700"
  },
  {
    "label":"4637105 - comercio atacadista de massas alimenticias",
    "value":"4637105"
  },
  {
    "label":"5912002 - servicos de mixagem sonora em producao audiovisual",
    "value":"5912002"
  },
  {
    "label":"0910600 - atividades de apoio a extracao de petroleo e gas natural",
    "value":"0910600"
  },
  {
    "label":"0121102 - cultivo de morango",
    "value":"0121102"
  },
  {
    "label":"2311700 - fabricacao de vidro plano e de seguranca",
    "value":"2311700"
  },
  {
    "label":"8599602 - cursos de pilotagem",
    "value":"8599602"
  },
  {
    "label":"4291000 - obras portuarias, maritimas e fluviais",
    "value":"4291000"
  },
  {
    "label":"0210199 - producao de produtos nao-madeireiros nao especificados anteriormente em florestas plantadas",
    "value":"0210199"
  },
  {
    "label":"8730102 - albergues assistenciais",
    "value":"8730102"
  },
  {
    "label":"4789009 - comercio varejista de armas e municoes",
    "value":"4789009"
  },
  {
    "label":"2221800 - fabricacao de laminados planos e tubulares de material plastico",
    "value":"2221800"
  },
  {
    "label":"8542200 - educacao profissional de nivel tecnologico",
    "value":"8542200"
  },
  {
    "label":"6511102 - planos de auxilio-funeral",
    "value":"6511102"
  },
  {
    "label":"3513100 - comercio atacadista de energia eletrica",
    "value":"3513100"
  },
  {
    "label":"3299005 - fabricacao de aviamentos para costura",
    "value":"3299005"
  },
  {
    "label":"6461100 - holdings de instituicoes financeiras",
    "value":"6461100"
  },
  {
    "label":"0119901 - cultivo de abacaxi",
    "value":"0119901"
  },
  {
    "label":"1721400 - fabricacao de papel",
    "value":"1721400"
  },
  {
    "label":"3092000 - fabricacao de bicicletas e triciclos nao-motorizados, pecas e acessorios",
    "value":"3092000"
  },
  {
    "label":"9001903 - producao de espetaculos de danca",
    "value":"9001903"
  },
  {
    "label":"2733300 - fabricacao de fios, cabos e condutores eletricos isolados",
    "value":"2733300"
  },
  {
    "label":"8640206 - servicos de ressonancia magnetica",
    "value":"8640206"
  },
  {
    "label":"3821100 - tratamento e disposicao de residuos nao-perigosos",
    "value":"3821100"
  },
  {
    "label":"1062700 - moagem de trigo e fabricacao de derivados",
    "value":"1062700"
  },
  {
    "label":"0220901 - extracao de madeira em florestas nativas",
    "value":"0220901"
  },
  {
    "label":"0155504 - criacao de aves, exceto galinaceos",
    "value":"0155504"
  },
  {
    "label":"4618403 - representantes comerciais e agentes do comercio de jornais, revistas e outras publicacoes",
    "value":"4618403"
  },
  {
    "label":"0220902 - producao de carvao vegetal - florestas nativas",
    "value":"0220902"
  },
  {
    "label":"5250805 - operador de transporte multimodal - otm",
    "value":"5250805"
  },
  {
    "label":"0710301 - extracao de minerio de ferro",
    "value":"0710301"
  },
  {
    "label":"4651602 - comercio atacadista de suprimentos para informatica",
    "value":"4651602"
  },
  {
    "label":"8423000 - justica",
    "value":"8423000"
  },
  {
    "label":"5021102 - transporte por navegacao interior de carga, intermunicipal, interestadual e internacional, exceto travessia",
    "value":"5021102"
  },
  {
    "label":"1122403 - fabricacao de refrescos, xaropes e pos para refrescos, exceto refrescos de frutas",
    "value":"1122403"
  },
  {
    "label":"0810009 - extracao de basalto e beneficiamento associado",
    "value":"0810009"
  },
  {
    "label":"0155502 - producao de pintos de um dia",
    "value":"0155502"
  },
  {
    "label":"2732500 - fabricacao de material eletrico para instalacoes em circuito de consumo",
    "value":"2732500"
  },
  {
    "label":"8640210 - servicos de quimioterapia",
    "value":"8640210"
  },
  {
    "label":"2945000 - fabricacao de material eletrico e eletronico para veiculos automotores, exceto baterias",
    "value":"2945000"
  },
  {
    "label":"1830003 - reproducao de software em qualquer suporte",
    "value":"1830003"
  },
  {
    "label":"2091600 - fabricacao de adesivos e selantes",
    "value":"2091600"
  },
  {
    "label":"8030700 - atividades de investigacao particular",
    "value":"8030700"
  },
  {
    "label":"8690904 - atividades de podologia",
    "value":"8690904"
  },
  {
    "label":"1312000 - preparacao e fiacao de fibras texteis naturais, exceto algodao",
    "value":"1312000"
  },
  {
    "label":"9101500 - atividades de bibliotecas e arquivos",
    "value":"9101500"
  },
  {
    "label":"1122499 - fabricacao de outras bebidas nao-alcoolicas nao especificadas anteriormente",
    "value":"1122499"
  },
  {
    "label":"8690903 - atividades de acupuntura",
    "value":"8690903"
  },
  {
    "label":"2825900 - fabricacao de maquinas e equipamentos para saneamento basico e ambiental, pecas e acessorios",
    "value":"2825900"
  },
  {
    "label":"6612603 - corretoras de cambio",
    "value":"6612603"
  },
  {
    "label":"6120599 - servicos de telecomunicacoes sem fio nao especificados anteriormente",
    "value":"6120599"
  },
  {
    "label":"0155503 - criacao de outros galinaceos, exceto para corte",
    "value":"0155503"
  },
  {
    "label":"3701100 - gestao de redes de esgoto",
    "value":"3701100"
  },
  {
    "label":"4649405 - comercio atacadista de artigos de tapecaria; persianas e cortinas",
    "value":"4649405"
  },
  {
    "label":"2864000 - fabricacao de maquinas e equipamentos para as industrias do vestuario, do couro e de calcados, pecas e acessorios",
    "value":"2864000"
  },
  {
    "label":"1323500 - tecelagem de fios de fibras artificiais e sinteticas",
    "value":"1323500"
  },
  {
    "label":"4211102 - pintura para sinalizacao em pistas rodoviarias e aeroportos",
    "value":"4211102"
  },
  {
    "label":"6612604 - corretoras de contratos de mercadorias",
    "value":"6612604"
  },
  {
    "label":"1013902 - preparacao de subprodutos do abate",
    "value":"1013902"
  },
  {
    "label":"1099601 - fabricacao de vinagres",
    "value":"1099601"
  },
  {
    "label":"1111902 - fabricacao de outras aguardentes e bebidas destiladas",
    "value":"1111902"
  },
  {
    "label":"1011205 - matadouro - abate de reses sob contrato - exceto abate de suinos",
    "value":"1011205"
  },
  {
    "label":"2121101 - fabricacao de medicamentos alopaticos para uso humano",
    "value":"2121101"
  },
  {
    "label":"1020101 - preservacao de peixes, crustaceos e moluscos",
    "value":"1020101"
  },
  {
    "label":"6619304 - caixas eletronicos",
    "value":"6619304"
  },
  {
    "label":"3314704 - manutencao e reparacao de compressores",
    "value":"3314704"
  },
  {
    "label":"3316301 - manutencao e reparacao de aeronaves, exceto a manutencao na pista",
    "value":"3316301"
  },
  {
    "label":"3314705 - manutencao e reparacao de equipamentos de transmissao para fins industriais",
    "value":"3314705"
  },
  {
    "label":"2622100 - fabricacao de perifericos para equipamentos de informatica",
    "value":"2622100"
  },
  {
    "label":"4649406 - comercio atacadista de lustres, luminarias e abajures",
    "value":"4649406"
  },
  {
    "label":"2621300 - fabricacao de equipamentos de informatica",
    "value":"2621300"
  },
  {
    "label":"3250707 - fabricacao de artigos opticos",
    "value":"3250707"
  },
  {
    "label":"3511502 - atividades de coordenacao e controle da operacao da geracao e transmissao de energia eletrica",
    "value":"3511502"
  },
  {
    "label":"0133405 - cultivo de coco-da-baia",
    "value":"0133405"
  },
  {
    "label":"4541201 - comercio por atacado de motocicletas e motonetas",
    "value":"4541201"
  },
  {
    "label":"4637103 - comercio atacadista de oleos e gorduras",
    "value":"4637103"
  },
  {
    "label":"4649403 - comercio atacadista de bicicletas, triciclos e outros veiculos recreativos",
    "value":"4649403"
  },
  {
    "label":"4329102 - instalacao de equipamentos para orientacao a navegacao maritima fluvial e lacustre",
    "value":"4329102"
  },
  {
    "label":"5030101 - navegacao de apoio maritimo",
    "value":"5030101"
  },
  {
    "label":"1122401 - fabricacao de refrigerantes",
    "value":"1122401"
  },
  {
    "label":"3012100 - construcao de embarcacoes para esporte e lazer",
    "value":"3012100"
  },
  {
    "label":"0153901 - criacao de caprinos",
    "value":"0153901"
  },
  {
    "label":"0990403 - atividades de apoio a extracao de minerais nao-metalicos",
    "value":"0990403"
  },
  {
    "label":"1811301 - impressao de jornais",
    "value":"1811301"
  },
  {
    "label":"4637102 - comercio atacadista de acucar",
    "value":"4637102"
  },
  {
    "label":"2759799 - fabricacao de outros aparelhos eletrodomesticos nao especificados anteriormente, pecas e acessorios",
    "value":"2759799"
  },
  {
    "label":"2941700 - fabricacao de pecas e acessorios para o sistema motor de veiculos automotores",
    "value":"2941700"
  },
  {
    "label":"1012103 - frigorifico - abate de suinos",
    "value":"1012103"
  },
  {
    "label":"4319300 - servicos de preparacao do terreno nao especificados anteriormente",
    "value":"4319300"
  },
  {
    "label":"9603303 - servicos de sepultamento",
    "value":"9603303"
  },
  {
    "label":"2223400 - fabricacao de tubos e acessorios de material plastico para uso na construcao",
    "value":"2223400"
  },
  {
    "label":"0220999 - coleta de produtos nao-madeireiros nao especificados anteriormente em florestas nativas",
    "value":"0220999"
  },
  {
    "label":"6541300 - previdencia complementar fechada",
    "value":"6541300"
  },
  {
    "label":"2591800 - fabricacao de embalagens metalicas",
    "value":"2591800"
  },
  {
    "label":"2660400 - fabricacao de aparelhos eletromedicos e eletroterapeuticos e equipamentos de irradiacao",
    "value":"2660400"
  },
  {
    "label":"4649407 - comercio atacadista de filmes, cds, dvds, fitas e discos",
    "value":"4649407"
  },
  {
    "label":"4299501 - construcao de instalacoes esportivas e recreativas",
    "value":"4299501"
  },
  {
    "label":"1421500 - fabricacao de meias",
    "value":"1421500"
  },
  {
    "label":"4623105 - comercio atacadista de cacau",
    "value":"4623105"
  },
  {
    "label":"0159899 - criacao de outros animais nao especificados anteriormente",
    "value":"0159899"
  },
  {
    "label":"2822401 - fabricacao de maquinas, equipamentos e aparelhos para transporte e elevacao de pessoas, pecas e acessorios",
    "value":"2822401"
  },
  {
    "label":"4636201 - comercio atacadista de fumo beneficiado",
    "value":"4636201"
  },
  {
    "label":"0135100 - cultivo de cacau",
    "value":"0135100"
  },
  {
    "label":"6612601 - corretoras de titulos e valores mobiliarios",
    "value":"6612601"
  },
  {
    "label":"2122000 - fabricacao de medicamentos para uso veterinario",
    "value":"2122000"
  },
  {
    "label":"9601702 - tinturarias",
    "value":"9601702"
  },
  {
    "label":"3314721 - manutencao e reparacao de maquinas e aparelhos para a industria de celulose, papel e papelao e artefatos",
    "value":"3314721"
  },
  {
    "label":"2073800 - fabricacao de impermeabilizantes, solventes e produtos afins",
    "value":"2073800"
  },
  {
    "label":"4221901 - construcao de barragens e represas para geracao de energia eletrica",
    "value":"4221901"
  },
  {
    "label":"7719501 - locacao de embarcacoes sem tripulacao, exceto para fins recreativos",
    "value":"7719501"
  },
  {
    "label":"3839401 - usinas de compostagem",
    "value":"3839401"
  },
  {
    "label":"5221400 - concessionarias de rodovias, pontes, tuneis e servicos relacionados",
    "value":"5221400"
  },
  {
    "label":"0133408 - cultivo de mamao",
    "value":"0133408"
  },
  {
    "label":"0892401 - extracao de sal marinho",
    "value":"0892401"
  },
  {
    "label":"2441501 - producao de aluminio e suas ligas em formas primarias",
    "value":"2441501"
  },
  {
    "label":"2013401 - fabricacao de adubos e fertilizantes organo-minerais",
    "value":"2013401"
  },
  {
    "label":"0162801 - servico de inseminacao artificial em animais",
    "value":"0162801"
  },
  {
    "label":"6141800 - operadoras de televisao por assinatura por cabo",
    "value":"6141800"
  },
  {
    "label":"8711503 - atividades de assistencia a deficientes fisicos, imunodeprimidos e convalescentes",
    "value":"8711503"
  },
  {
    "label":"2815102 - fabricacao de equipamentos de transmissao para fins industriais, exceto rolamentos",
    "value":"2815102"
  },
  {
    "label":"2710401 - fabricacao de geradores de corrente continua e alternada, pecas e acessorios",
    "value":"2710401"
  },
  {
    "label":"8413200 - regulacao das atividades economicas",
    "value":"8413200"
  },
  {
    "label":"2424502 - producao de relaminados, trefilados e perfilados de aco, exceto arames",
    "value":"2424502"
  },
  {
    "label":"6130200 - telecomunicacoes por satelite",
    "value":"6130200"
  },
  {
    "label":"2790202 - fabricacao de equipamentos para sinalizacao e alarme",
    "value":"2790202"
  },
  {
    "label":"1032501 - fabricacao de conservas de palmito",
    "value":"1032501"
  },
  {
    "label":"2631100 - fabricacao de equipamentos transmissores de comunicacao, pecas e acessorios",
    "value":"2631100"
  },
  {
    "label":"0899102 - extracao de quartzo",
    "value":"0899102"
  },
  {
    "label":"3099700 - fabricacao de equipamentos de transporte nao especificados anteriormente",
    "value":"3099700"
  },
  {
    "label":"3314703 - manutencao e reparacao de valvulas industriais",
    "value":"3314703"
  },
  {
    "label":"2944100 - fabricacao de pecas e acessorios para o sistema de direcao e suspensao de veiculos automotores",
    "value":"2944100"
  },
  {
    "label":"2031200 - fabricacao de resinas termoplasticas",
    "value":"2031200"
  },
  {
    "label":"3250703 - fabricacao de aparelhos e utensilios para correcao de defeitos fisicos e aparelhos ortopedicos em geral sob encomenda",
    "value":"3250703"
  },
  {
    "label":"2014200 - fabricacao de gases industriais",
    "value":"2014200"
  },
  {
    "label":"6542100 - previdencia complementar aberta",
    "value":"6542100"
  },
  {
    "label":"5022001 - transporte por navegacao interior de passageiros em linhas regulares, municipal, exceto travessia",
    "value":"5022001"
  },
  {
    "label":"0810008 - extracao de saibro e beneficiamento associado",
    "value":"0810008"
  },
  {
    "label":"3313902 - manutencao e reparacao de baterias e acumuladores eletricos, exceto para veiculos",
    "value":"3313902"
  },
  {
    "label":"0891600 - extracao de minerais para fabricacao de adubos, fertilizantes e outros produtos quimicos",
    "value":"0891600"
  },
  {
    "label":"1532700 - fabricacao de tenis de qualquer material",
    "value":"1532700"
  },
  {
    "label":"0159804 - criacao de bicho-da-seda",
    "value":"0159804"
  },
  {
    "label":"3822000 - tratamento e disposicao de residuos perigosos",
    "value":"3822000"
  },
  {
    "label":"0311602 - pesca de crustaceos e moluscos em agua salgada",
    "value":"0311602"
  },
  {
    "label":"0810003 - extracao de marmore e beneficiamento associado",
    "value":"0810003"
  },
  {
    "label":"8299704 - leiloeiros independentes",
    "value":"8299704"
  },
  {
    "label":"2866600 - fabricacao de maquinas e equipamentos para a industria do plastico, pecas e acessorios",
    "value":"2866600"
  },
  {
    "label":"8711504 - centros de apoio a pacientes com cancer e com aids",
    "value":"8711504"
  },
  {
    "label":"0721901 - extracao de minerio de aluminio",
    "value":"0721901"
  },
  {
    "label":"8711505 - condominios residenciais para idosos e deficientes fisicos",
    "value":"8711505"
  },
  {
    "label":"6022501 - programadoras",
    "value":"6022501"
  },
  {
    "label":"5030102 - navegacao de apoio portuario",
    "value":"5030102"
  },
  {
    "label":"0133411 - cultivo de pessego",
    "value":"0133411"
  },
  {
    "label":"0210105 - cultivo de especies madeireiras, exceto eucalipto, acacia-negra, pinus e teca",
    "value":"0210105"
  },
  {
    "label":"1081301 - beneficiamento de cafe",
    "value":"1081301"
  },
  {
    "label":"0133401 - cultivo de acai",
    "value":"0133401"
  },
  {
    "label":"6520100 - sociedade seguradora de seguros saude",
    "value":"6520100"
  },
  {
    "label":"2863100 - fabricacao de maquinas e equipamentos para a industria textil, pecas e acessorios",
    "value":"2863100"
  },
  {
    "label":"8640211 - servicos de radioterapia",
    "value":"8640211"
  },
  {
    "label":"2320600 - fabricacao de cimento",
    "value":"2320600"
  },
  {
    "label":"6110899 - servicos de telecomunicacoes por fio nao especificados anteriormente",
    "value":"6110899"
  },
  {
    "label":"2411300 - producao de ferro-gusa",
    "value":"2411300"
  },
  {
    "label":"8621602 - servicos moveis de atendimento a urgencias, exceto por uti movel",
    "value":"8621602"
  },
  {
    "label":"2342701 - fabricacao de azulejos e pisos",
    "value":"2342701"
  },
  {
    "label":"4623103 - comercio atacadista de algodao",
    "value":"4623103"
  },
  {
    "label":"0139304 - cultivo de plantas para condimento, exceto pimenta-do-reino",
    "value":"0139304"
  },
  {
    "label":"3314714 - manutencao e reparacao de maquinas e equipamentos para a prospeccao e extracao de petroleo",
    "value":"3314714"
  },
  {
    "label":"6499902 - sociedades de investimento",
    "value":"6499902"
  },
  {
    "label":"0111303 - cultivo de trigo",
    "value":"0111303"
  },
  {
    "label":"0311604 - atividades de apoio a pesca em agua salgada",
    "value":"0311604"
  },
  {
    "label":"4940000 - transporte dutoviario",
    "value":"4940000"
  },
  {
    "label":"3312104 - manutencao e reparacao de equipamentos e instrumentos opticos",
    "value":"3312104"
  },
  {
    "label":"2531401 - producao de forjados de aco",
    "value":"2531401"
  },
  {
    "label":"2330303 - fabricacao de artefatos de fibrocimento para uso na construcao",
    "value":"2330303"
  },
  {
    "label":"1922599 - fabricacao de outros produtos derivados do petroleo, exceto produtos do refino",
    "value":"1922599"
  },
  {
    "label":"9603305 - servicos de somatoconservacao",
    "value":"9603305"
  },
  {
    "label":"0220906 - conservacao de florestas nativas",
    "value":"0220906"
  },
  {
    "label":"6190602 - provedores de voz sobre protocolo internet - voip",
    "value":"6190602"
  },
  {
    "label":"1099602 - fabricacao de pos alimenticios",
    "value":"1099602"
  },
  {
    "label":"2943300 - fabricacao de pecas e acessorios para o sistema de freios de veiculos automotores",
    "value":"2943300"
  },
  {
    "label":"3315500 - manutencao e reparacao de veiculos ferroviarios",
    "value":"3315500"
  },
  {
    "label":"3314715 - manutencao e reparacao de maquinas e equipamentos para uso na extracao mineral, exceto na extracao de petroleo",
    "value":"3314715"
  },
  {
    "label":"2910701 - fabricacao de automoveis, camionetas e utilitarios",
    "value":"2910701"
  },
  {
    "label":"2722801 - fabricacao de baterias e acumuladores para veiculos automotores",
    "value":"2722801"
  },
  {
    "label":"4634699 - comercio atacadista de carnes e derivados de outros animais",
    "value":"4634699"
  },
  {
    "label":"3091102 - fabricacao de pecas e acessorios para motocicletas",
    "value":"3091102"
  },
  {
    "label":"0810005 - extracao de gesso e caulim",
    "value":"0810005"
  },
  {
    "label":"2531402 - producao de forjados de metais nao-ferrosos e suas ligas",
    "value":"2531402"
  },
  {
    "label":"6440900 - arrendamento mercantil",
    "value":"6440900"
  },
  {
    "label":"0119907 - cultivo de melao",
    "value":"0119907"
  },
  {
    "label":"1710900 - fabricacao de celulose e outras pastas para a fabricacao de papel",
    "value":"1710900"
  },
  {
    "label":"2422901 - producao de laminados planos de aco ao carbono, revestidos ou nao",
    "value":"2422901"
  },
  {
    "label":"7420005 - servicos de microfilmagem",
    "value":"7420005"
  },
  {
    "label":"4542102 - comercio sob consignacao de motocicletas e motonetas",
    "value":"4542102"
  },
  {
    "label":"2942500 - fabricacao de pecas e acessorios para os sistemas de marcha e transmissao de veiculos automotores",
    "value":"2942500"
  },
  {
    "label":"5112999 - outros servicos de transporte aereo de passageiros nao-regular",
    "value":"5112999"
  },
  {
    "label":"2092402 - fabricacao de artigos pirotecnicos",
    "value":"2092402"
  },
  {
    "label":"2051700 - fabricacao de defensivos agricolas",
    "value":"2051700"
  },
  {
    "label":"3314716 - manutencao e reparacao de tratores, exceto agricolas",
    "value":"3314716"
  },
  {
    "label":"0152103 - criacao de asininos e muares",
    "value":"0152103"
  },
  {
    "label":"0162802 - servico de tosquiamento de ovinos",
    "value":"0162802"
  },
  {
    "label":"5240101 - operacao dos aeroportos e campos de aterrissagem",
    "value":"5240101"
  },
  {
    "label":"3314722 - manutencao e reparacao de maquinas e aparelhos para a industria do plastico",
    "value":"3314722"
  },
  {
    "label":"2110600 - fabricacao de produtos farmoquimicos",
    "value":"2110600"
  },
  {
    "label":"6450600 - sociedades de capitalizacao",
    "value":"6450600"
  },
  {
    "label":"0321304 - criacao de peixes ornamentais em agua salgada e salobra",
    "value":"0321304"
  },
  {
    "label":"6120502 - servico movel especializado - sme",
    "value":"6120502"
  },
  {
    "label":"3250709 - servico de laboratorio optico",
    "value":"3250709"
  },
  {
    "label":"9329802 - exploracao de boliches",
    "value":"9329802"
  },
  {
    "label":"4623104 - comercio atacadista de fumo em folha nao beneficiado",
    "value":"4623104"
  },
  {
    "label":"8421300 - relacoes exteriores",
    "value":"8421300"
  },
  {
    "label":"1313800 - fiacao de fibras artificiais e sinteticas",
    "value":"1313800"
  },
  {
    "label":"0322107 - atividades de apoio a aquicultura em agua doce",
    "value":"0322107"
  },
  {
    "label":"0133407 - cultivo de maca",
    "value":"0133407"
  },
  {
    "label":"2431800 - producao de tubos de aco com costura",
    "value":"2431800"
  },
  {
    "label":"0139301 - cultivo de cha-da-india",
    "value":"0139301"
  },
  {
    "label":"8630507 - atividades de reproducao humana assistida",
    "value":"8630507"
  },
  {
    "label":"0723501 - extracao de minerio de manganes",
    "value":"0723501"
  },
  {
    "label":"4911600 - transporte ferroviario de carga",
    "value":"4911600"
  },
  {
    "label":"0322105 - ranicultura",
    "value":"0322105"
  },
  {
    "label":"1742702 - fabricacao de absorventes higienicos",
    "value":"1742702"
  },
  {
    "label":"2811900 - fabricacao de motores e turbinas, pecas e acessorios, exceto para avioes e veiculos rodoviarios",
    "value":"2811900"
  },
  {
    "label":"2412100 - producao de ferroligas",
    "value":"2412100"
  },
  {
    "label":"1932200 - fabricacao de biocombustiveis, exceto alcool",
    "value":"1932200"
  },
  {
    "label":"2439300 - producao de outros tubos de ferro e aco",
    "value":"2439300"
  },
  {
    "label":"4222702 - obras de irrigacao",
    "value":"4222702"
  },
  {
    "label":"2211100 - fabricacao de pneumaticos e de camaras-de-ar",
    "value":"2211100"
  },
  {
    "label":"6110802 - servicos de redes de transportes de telecomunicacoes - srtt",
    "value":"6110802"
  },
  {
    "label":"1099607 - fabricacao de alimentos dieteticos e complementos alimentares",
    "value":"1099607"
  },
  {
    "label":"2710403 - fabricacao de motores eletricos, pecas e acessorios",
    "value":"2710403"
  },
  {
    "label":"7319001 - criacao de estandes para feiras e exposicoes",
    "value":"7319001"
  },
  {
    "label":"1042200 - fabricacao de oleos vegetais refinados, exceto oleo de milho",
    "value":"1042200"
  },
  {
    "label":"3900500 - descontaminacao e outros servicos de gestao de residuos",
    "value":"3900500"
  },
  {
    "label":"0322102 - criacao de camaroes em agua doce",
    "value":"0322102"
  },
  {
    "label":"6499905 - concessao de credito pelas oscip",
    "value":"6499905"
  },
  {
    "label":"2832100 - fabricacao de equipamentos para irrigacao agricola, pecas e acessorios",
    "value":"2832100"
  },
  {
    "label":"0990402 - atividades de apoio a extracao de minerais metalicos nao-ferrosos",
    "value":"0990402"
  },
  {
    "label":"1830001 - reproducao de som em qualquer suporte",
    "value":"1830001"
  },
  {
    "label":"5822102 - edicao integrada a impressao de jornais nao diarios",
    "value":"5822102"
  },
  {
    "label":"2851800 - fabricacao de maquinas e equipamentos para a prospeccao e extracao de petroleo, pecas e acessorios",
    "value":"2851800"
  },
  {
    "label":"2092401 - fabricacao de polvoras, explosivos e detonantes",
    "value":"2092401"
  },
  {
    "label":"1072401 - fabricacao de acucar de cana refinado",
    "value":"1072401"
  },
  {
    "label":"0119902 - cultivo de alho",
    "value":"0119902"
  },
  {
    "label":"6619303 - representacoes de bancos estrangeiros",
    "value":"6619303"
  },
  {
    "label":"4922103 - transporte rodoviario coletivo de passageiros, com itinerario fixo, internacional",
    "value":"4922103"
  },
  {
    "label":"6435202 - associacoes de poupanca e emprestimo",
    "value":"6435202"
  },
  {
    "label":"0322199 - cultivos e semicultivos da aquicultura em agua doce nao especificados anteriormente",
    "value":"0322199"
  },
  {
    "label":"4511106 - comercio por atacado de onibus e microonibus novos e usados",
    "value":"4511106"
  },
  {
    "label":"5239701 - servicos de praticagem",
    "value":"5239701"
  },
  {
    "label":"2040100 - fabricacao de fibras artificiais e sinteticas",
    "value":"2040100"
  },
  {
    "label":"9603302 - servicos de cremacao",
    "value":"9603302"
  },
  {
    "label":"1722200 - fabricacao de cartolina e papel-cartao",
    "value":"1722200"
  },
  {
    "label":"2330304 - fabricacao de casas pre-moldadas de concreto",
    "value":"2330304"
  },
  {
    "label":"2123800 - fabricacao de preparacoes farmaceuticas",
    "value":"2123800"
  },
  {
    "label":"2831300 - fabricacao de tratores agricolas, pecas e acessorios",
    "value":"2831300"
  },
  {
    "label":"3520402 - distribuicao de combustiveis gasosos por redes urbanas",
    "value":"3520402"
  },
  {
    "label":"3250702 - fabricacao de mobiliario para uso medico, cirurgico, odontologico e de laboratorio",
    "value":"3250702"
  },
  {
    "label":"0500301 - extracao de carvao mineral",
    "value":"0500301"
  },
  {
    "label":"0892403 - refino e outros tratamentos do sal",
    "value":"0892403"
  },
  {
    "label":"0210104 - cultivo de teca",
    "value":"0210104"
  },
  {
    "label":"6431000 - bancos multiplos, sem carteira comercial",
    "value":"6431000"
  },
  {
    "label":"9200399 - exploracao de jogos de azar e apostas nao especificados anteriormente",
    "value":"9200399"
  },
  {
    "label":"4511105 - comercio por atacado de reboques e semi-reboques novos e usados",
    "value":"4511105"
  },
  {
    "label":"2442300 - metalurgia dos metais preciosos",
    "value":"2442300"
  },
  {
    "label":"2821602 - fabricacao de estufas e fornos eletricos para fins industriais, pecas e acessorios",
    "value":"2821602"
  },
  {
    "label":"2423702 - producao de laminados longos de aco, exceto tubos",
    "value":"2423702"
  },
  {
    "label":"0312404 - atividades de apoio a pesca em agua doce",
    "value":"0312404"
  },
  {
    "label":"0133403 - cultivo de caju",
    "value":"0133403"
  },
  {
    "label":"3520401 - producao de gas; processamento de gas natural",
    "value":"3520401"
  },
  {
    "label":"2424501 - producao de arames de aco",
    "value":"2424501"
  },
  {
    "label":"3299001 - fabricacao de guarda-chuvas e similares",
    "value":"3299001"
  },
  {
    "label":"2670101 - fabricacao de equipamentos e instrumentos opticos, pecas e acessorios",
    "value":"2670101"
  },
  {
    "label":"6143400 - operadoras de televisao por assinatura por satelite",
    "value":"6143400"
  },
  {
    "label":"6437900 - sociedades de credito ao microempreendedor",
    "value":"6437900"
  },
  {
    "label":"5231101 - administracao da infra-estrutura portuaria",
    "value":"5231101"
  },
  {
    "label":"0990401 - atividades de apoio a extracao de minerio de ferro",
    "value":"0990401"
  },
  {
    "label":"2814301 - fabricacao de compressores para uso industrial, pecas e acessorios",
    "value":"2814301"
  },
  {
    "label":"2824102 - fabricacao de aparelhos e equipamentos de ar condicionado para uso nao-industrial",
    "value":"2824102"
  },
  {
    "label":"9200302 - exploracao de apostas em corridas de cavalos",
    "value":"9200302"
  },
  {
    "label":"1011203 - frigorifico - abate de ovinos e caprinos",
    "value":"1011203"
  },
  {
    "label":"2740601 - fabricacao de lampadas",
    "value":"2740601"
  },
  {
    "label":"8299705 - servicos de levantamento de fundos sob contrato",
    "value":"8299705"
  },
  {
    "label":"1741901 - fabricacao de formularios continuos",
    "value":"1741901"
  },
  {
    "label":"2022300 - fabricacao de intermediarios para plastificantes, resinas e fibras",
    "value":"2022300"
  },
  {
    "label":"2790201 - fabricacao de eletrodos, contatos e outros artigos de carvao e grafita para uso eletrico, eletroimas e isoladores",
    "value":"2790201"
  },
  {
    "label":"3250704 - fabricacao de aparelhos e utensilios para correcao de defeitos fisicos e aparelhos ortopedicos em geral, exceto sob encomenda",
    "value":"3250704"
  },
  {
    "label":"0710302 - pelotizacao, sinterizacao e outros beneficiamentos de minerio de ferro",
    "value":"0710302"
  },
  {
    "label":"1220401 - fabricacao de cigarros",
    "value":"1220401"
  },
  {
    "label":"6434400 - agencias de fomento",
    "value":"6434400"
  },
  {
    "label":"0139305 - cultivo de dende",
    "value":"0139305"
  },
  {
    "label":"0724302 - beneficiamento de minerio de metais preciosos",
    "value":"0724302"
  },
  {
    "label":"1210700 - processamento industrial do fumo",
    "value":"1210700"
  },
  {
    "label":"3316302 - manutencao de aeronaves na pista",
    "value":"3316302"
  },
  {
    "label":"2522500 - fabricacao de caldeiras geradoras de vapor, exceto para aquecimento central e para veiculos",
    "value":"2522500"
  },
  {
    "label":"1314600 - fabricacao de linhas para costurar e bordar",
    "value":"1314600"
  },
  {
    "label":"7719502 - locacao de aeronaves sem tripulacao",
    "value":"7719502"
  },
  {
    "label":"3041500 - fabricacao de aeronaves",
    "value":"3041500"
  },
  {
    "label":"3530100 - producao e distribuicao de vapor, agua quente e ar condicionado",
    "value":"3530100"
  },
  {
    "label":"1830002 - reproducao de video em qualquer suporte",
    "value":"1830002"
  },
  {
    "label":"5012201 - transporte maritimo de longo curso - carga",
    "value":"5012201"
  },
  {
    "label":"2072000 - fabricacao de tintas de impressao",
    "value":"2072000"
  },
  {
    "label":"3091101 - fabricacao de motocicletas",
    "value":"3091101"
  },
  {
    "label":"1012102 - abate de pequenos animais",
    "value":"1012102"
  },
  {
    "label":"0321301 - criacao de peixes em agua salgada e salobra",
    "value":"0321301"
  },
  {
    "label":"2032100 - fabricacao de resinas termofixas",
    "value":"2032100"
  },
  {
    "label":"8650007 - atividades de terapia de nutricao enteral e parenteral",
    "value":"8650007"
  },
  {
    "label":"6424702 - cooperativas centrais de credito",
    "value":"6424702"
  },
  {
    "label":"8640214 - servicos de bancos de celulas e tecidos humanos",
    "value":"8640214"
  },
  {
    "label":"2449103 - fabricacao de anodos para galvanoplastia",
    "value":"2449103"
  },
  {
    "label":"2033900 - fabricacao de elastomeros",
    "value":"2033900"
  },
  {
    "label":"0810010 - beneficiamento de gesso e caulim associado a extracao",
    "value":"0810010"
  },
  {
    "label":"1220402 - fabricacao de cigarrilhas e charutos",
    "value":"1220402"
  },
  {
    "label":"0116403 - cultivo de mamona",
    "value":"0116403"
  },
  {
    "label":"2721000 - fabricacao de pilhas, baterias e acumuladores eletricos, exceto para veiculos automotores",
    "value":"2721000"
  },
  {
    "label":"3031800 - fabricacao de locomotivas, vagoes e outros materiais rodantes",
    "value":"3031800"
  },
  {
    "label":"0729401 - extracao de minerios de niobio e titanio",
    "value":"0729401"
  },
  {
    "label":"4684202 - comercio atacadista de solventes",
    "value":"4684202"
  },
  {
    "label":"3240001 - fabricacao de jogos eletronicos",
    "value":"3240001"
  },
  {
    "label":"2722802 - recondicionamento de baterias e acumuladores para veiculos automotores",
    "value":"2722802"
  },
  {
    "label":"6432800 - bancos de investimento",
    "value":"6432800"
  },
  {
    "label":"0139303 - cultivo de pimenta-do-reino",
    "value":"0139303"
  },
  {
    "label":"0139302 - cultivo de erva-mate",
    "value":"0139302"
  },
  {
    "label":"4912402 - transporte ferroviario de passageiros municipal e em regiao metropolitana",
    "value":"4912402"
  },
  {
    "label":"6499903 - fundo garantidor de credito",
    "value":"6499903"
  },
  {
    "label":"6424701 - bancos cooperativos",
    "value":"6424701"
  },
  {
    "label":"0321303 - criacao de ostras e mexilhoes em agua salgada e salobra",
    "value":"0321303"
  },
  {
    "label":"0723502 - beneficiamento de minerio de manganes",
    "value":"0723502"
  },
  {
    "label":"0600003 - extracao e beneficiamento de areias betuminosas",
    "value":"0600003"
  },
  {
    "label":"2349401 - fabricacao de material sanitario de ceramica",
    "value":"2349401"
  },
  {
    "label":"5231103 - gestao de terminais aquaviarios",
    "value":"5231103"
  },
  {
    "label":"2853400 - fabricacao de tratores, pecas e acessorios, exceto agricolas",
    "value":"2853400"
  },
  {
    "label":"2312500 - fabricacao de embalagens de vidro",
    "value":"2312500"
  },
  {
    "label":"0722702 - beneficiamento de minerio de estanho",
    "value":"0722702"
  },
  {
    "label":"2550102 - fabricacao de armas de fogo, outras armas e municoes",
    "value":"2550102"
  },
  {
    "label":"6435201 - sociedades de credito imobiliario",
    "value":"6435201"
  },
  {
    "label":"1012104 - matadouro - abate de suinos sob contrato",
    "value":"1012104"
  },
  {
    "label":"1910100 - coquerias",
    "value":"1910100"
  },
  {
    "label":"0722701 - extracao de minerio de estanho",
    "value":"0722701"
  },
  {
    "label":"6611803 - bolsa de mercadorias e futuros",
    "value":"6611803"
  },
  {
    "label":"0114800 - cultivo de fumo",
    "value":"0114800"
  },
  {
    "label":"0500302 - beneficiamento de carvao mineral",
    "value":"0500302"
  },
  {
    "label":"3211603 - cunhagem de moedas e medalhas",
    "value":"3211603"
  },
  {
    "label":"2121102 - fabricacao de medicamentos homeopaticos para uso humano",
    "value":"2121102"
  },
  {
    "label":"2532202 - metalurgia do po",
    "value":"2532202"
  },
  {
    "label":"2121103 - fabricacao de medicamentos fitoterapicos para uso humano",
    "value":"2121103"
  },
  {
    "label":"0321305 - atividades de apoio a aquicultura em agua salgada e salobra",
    "value":"0321305"
  },
  {
    "label":"6530800 - resseguros",
    "value":"6530800"
  },
  {
    "label":"2652300 - fabricacao de cronometros e relogios",
    "value":"2652300"
  },
  {
    "label":"3042300 - fabricacao de turbinas, motores e outros componentes e pecas para aeronaves",
    "value":"3042300"
  },
  {
    "label":"8640213 - servicos de litotripcia",
    "value":"8640213"
  },
  {
    "label":"2443100 - metalurgia do cobre",
    "value":"2443100"
  },
  {
    "label":"4950700 - trens turisticos, telefericos e similares",
    "value":"4950700"
  },
  {
    "label":"6438799 - outras instituicoes de intermediacao nao-monetaria nao especificadas anteriormente",
    "value":"6438799"
  },
  {
    "label":"2423701 - producao de tubos de aco sem costura",
    "value":"2423701"
  },
  {
    "label":"2449101 - producao de zinco em formas primarias",
    "value":"2449101"
  },
  {
    "label":"3240002 - fabricacao de mesas de bilhar, de sinuca e acessorios nao associada a locacao",
    "value":"3240002"
  },
  {
    "label":"2670102 - fabricacao de aparelhos fotograficos e cinematograficos, pecas e acessorios",
    "value":"2670102"
  },
  {
    "label":"2550101 - fabricacao de equipamento belico pesado, exceto veiculos militares de combate",
    "value":"2550101"
  },
  {
    "label":"2815101 - fabricacao de rolamentos para fins industriais",
    "value":"2815101"
  },
  {
    "label":"2099101 - fabricacao de chapas, filmes, papeis e outros materiais e produtos quimicos para fotografia",
    "value":"2099101"
  },
  {
    "label":"0210102 - cultivo de acacia-negra",
    "value":"0210102"
  },
  {
    "label":"0321399 - cultivos e semicultivos da aquicultura em agua salgada e salobra nao especificados anteriormente",
    "value":"0321399"
  },
  {
    "label":"1043100 - fabricacao de margarina e outras gorduras vegetais e de oleos nao-comestiveis de animais",
    "value":"1043100"
  },
  {
    "label":"1099603 - fabricacao de fermentos e leveduras",
    "value":"1099603"
  },
  {
    "label":"2421100 - producao de semi-acabados de aco",
    "value":"2421100"
  },
  {
    "label":"5011402 - transporte maritimo de cabotagem - passageiros",
    "value":"5011402"
  },
  {
    "label":"2829101 - fabricacao de maquinas de escrever, calcular e outros equipamentos nao-eletronicos para escritorio, pecas e acessorios",
    "value":"2829101"
  },
  {
    "label":"2759701 - fabricacao de aparelhos eletricos de uso pessoal, pecas e acessorios",
    "value":"2759701"
  },
  {
    "label":"2930102 - fabricacao de carrocerias para onibus",
    "value":"2930102"
  },
  {
    "label":"0220903 - coleta de castanha-do-para em florestas nativas",
    "value":"0220903"
  },
  {
    "label":"2011800 - fabricacao de cloro e alcalis",
    "value":"2011800"
  },
  {
    "label":"6619305 - operadoras de cartoes de debito",
    "value":"6619305"
  },
  {
    "label":"2824101 - fabricacao de aparelhos e equipamentos de ar condicionado para uso industrial",
    "value":"2824101"
  },
  {
    "label":"0159803 - criacao de escargo",
    "value":"0159803"
  },
  {
    "label":"1220403 - fabricacao de filtros para cigarros",
    "value":"1220403"
  },
  {
    "label":"0112102 - cultivo de juta",
    "value":"0112102"
  },
  {
    "label":"4712100 - comercio varejista de mercadorias em geral, com predominancia de produtos alimenticios - minimercados, mercearias e armazens",
    "value":"4712100"
  },
  {
    "label":"8299799 - outras atividades de servicos prestados principalmente as empresas nao especificadas anteriormente",
    "value":"8299799"
  },
  {
    "label":"4619200 - representantes comerciais e agentes do comercio de mercadorias em geral nao especializado",
    "value":"4619200"
  },
  {
    "label":"5912099 - atividades de pos-producao cinematografica, de videos e de programas de televisao nao especificadas anteriormente",
    "value":"5912099"
  },
  {
    "label":"4711302 - comercio varejista de mercadorias em geral, com predominancia de produtos alimenticios - supermercados",
    "value":"4711302"
  },
  {
    "label":"8230002 - casas de festas e eventos",
    "value":"8230002"
  },
  {
    "label":"6190699 - outras atividades de telecomunicacoes nao especificadas anteriormente",
    "value":"6190699"
  },
  {
    "label":"4613300 - representantes comerciais e agentes do comercio de madeira, material de construcao e ferragens",
    "value":"4613300"
  },
  {
    "label":"4633801 - comercio atacadista de frutas, verduras, raizes, tuberculos, hortalicas e legumes frescos",
    "value":"4633801"
  },
  {
    "label":"1622699 - fabricacao de outros artigos de carpintaria para construcao",
    "value":"1622699"
  },
  {
    "label":"1749400 - fabricacao de produtos de pastas celulosicas, papel, cartolina, papel-cartao e papelao ondulado nao especificados anteriormente",
    "value":"1749400"
  },
  {
    "label":"6619399 - outras atividades auxiliares dos servicos financeiros nao especificadas anteriormente",
    "value":"6619399"
  },
  {
    "label":"4646001 - comercio atacadista de cosmeticos e produtos de perfumaria",
    "value":"4646001"
  },
  {
    "label":"4649499 - comercio atacadista de outros equipamentos e artigos de uso pessoal e domestico nao especificados anteriormente",
    "value":"4649499"
  },
  {
    "label":"3314799 - manutencao e reparacao de outras maquinas e equipamentos para usos industriais nao especificados anteriormente",
    "value":"3314799"
  },
  {
    "label":"4211101 - construcao de rodovias e ferrovias",
    "value":"4211101"
  },
  {
    "label":"7719599 - locacao de outros meios de transporte nao especificados anteriormente, sem condutor",
    "value":"7719599"
  },
  {
    "label":"4922101 - transporte rodoviario coletivo de passageiros, com itinerario fixo, intermunicipal, exceto em regiao metropolitana",
    "value":"4922101"
  },
  {
    "label":"0159802 - criacao de animais de estimacao",
    "value":"0159802"
  },
  {
    "label":"7119799 - atividades tecnicas relacionadas a engenharia e arquitetura nao especificadas anteriormente",
    "value":"7119799"
  },
  {
    "label":"2869100 - fabricacao de maquinas e equipamentos para uso industrial especifico nao especificados anteriormente, pecas e acessorios",
    "value":"2869100"
  },
  {
    "label":"8720499 - atividades de assistencia psicossocial e a saude a portadores de disturbios psiquicos, deficiencia mental e dependencia quimica e grupos similares nao especificadas anteriormente",
    "value":"8720499"
  },
  {
    "label":"4618402 - representantes comerciais e agentes do comercio de instrumentos e materiais odonto-medico-hospitalares",
    "value":"4618402"
  },
  {
    "label":"6142600 - operadoras de televisao por assinatura por microondas",
    "value":"6142600"
  },
  {
    "label":"4687702 - comercio atacadista de residuos e sucatas nao-metalicos, exceto de papel e papelao",
    "value":"4687702"
  },
  {
    "label":"5030103 - servico de rebocadores e empurradores",
    "value":"5030103"
  },
  {
    "label":"6611801 - bolsa de valores",
    "value":"6611801"
  },
  {
    "label":"0729402 - extracao de minerio de tungstenio",
    "value":"0729402"
  },
  {
    "label":"2092403 - fabricacao de fosforos de seguranca",
    "value":"2092403"
  },
  {
    "label":"0899101 - extracao de grafita",
    "value":"0899101"
  },
  {
    "label":"0721902 - beneficiamento de minerio de aluminio",
    "value":"0721902"
  },
  {
    "label":"0170900 - caca e servicos relacionados",
    "value":"0170900"
  },
  {
    "label":"1099606 - fabricacao de adocantes naturais e artificiais",
    "value":"1099606"
  },
  {
    "label":"0322106 - criacao de jacare",
    "value":"0322106"
  },
  {
    "label":"6611804 - administracao de mercados de balcao organizados",
    "value":"6611804"
  },
  {
    "label":"1922501 - formulacao de combustiveis",
    "value":"1922501"
  },
  {
    "label":"1122404 - fabricacao de bebidas isotonicas",
    "value":"1122404"
  },
  {
    "label":"6410700 - banco central",
    "value":"6410700"
  },
  {
    "label":"4751200 - comercio varejista especializado de equipamentos e suprimentos de informatica",
    "value":"4751200"
  },
  {
    "label":"3511500 - geracao de energia eletrica",
    "value":"3511500"
  },
  {
    "label":"0899103 - extracao de amianto",
    "value":"0899103"
  },
  {
    "label":"0133406 - cultivo de guarana",
    "value":"0133406"
  },
  {
    "label":"6435203 - companhias hipotecarias",
    "value":"6435203"
  },
  {
    "label":"1011204 - frigorifico - abate de bufalinos",
    "value":"1011204"
  },
  {
    "label":"0312402 - pesca de crustaceos e moluscos em agua doce",
    "value":"0312402"
  },
  {
    "label":"1065102 - fabricacao de oleo de milho em bruto",
    "value":"1065102"
  },
  {
    "label":"4721101 - padaria e confeitaria com predominancia de producao propria",
    "value":"4721101"
  },
  {
    "label":"6438701 - bancos de cambio",
    "value":"6438701"
  },
  {
    "label":"2920402 - fabricacao de motores para caminhoes e onibus",
    "value":"2920402"
  },
  {
    "label":"0322103 - criacao de ostras e mexilhoes em agua doce",
    "value":"0322103"
  },
  {
    "label":"0600002 - extracao e beneficiamento de xisto",
    "value":"0600002"
  },
  {
    "label":"6499904 - caixas de financiamento de corporacoes",
    "value":"6499904"
  },
  {
    "label":"6619301 - servicos de liquidacao e custodia",
    "value":"6619301"
  },
  {
    "label":"2910702 - fabricacao de chassis com motor para automoveis, camionetas e utilitarios",
    "value":"2910702"
  },
  {
    "label":"2019301 - elaboracao de combustiveis nucleares",
    "value":"2019301"
  },
  {
    "label":"1072402 - fabricacao de acucar de cereais (dextrose) e de beterraba",
    "value":"1072402"
  },
  {
    "label":"3050400 - fabricacao de veiculos militares de combate",
    "value":"3050400"
  },
  {
    "label":"5130700 - transporte espacial",
    "value":"5130700"
  },
  {
    "label":"8690902 - atividades de banco de leite humano",
    "value":"8690902"
  },
  {
    "label":"0892402 - extracao de sal-gema",
    "value":"0892402"
  },
  {
    "label":"0210109 - producao de casca de acacia-negra - florestas plantadas",
    "value":"0210109"
  },
  {
    "label":"1091100 - fabricacao de produtos de panificacao",
    "value":"1091100"
  },
  {
    "label":"4633803 - comercio atacadista de coelhos e outros pequenos animais vivos para alimentacao",
    "value":"4633803"
  },
  {
    "label":"4781400 - comercio varejista de artigos do vestuario e acessorios",
    "value":"4781400"
  },
  {
    "label":"0810004 - extracao de calcario e dolomita e beneficiamento associado",
    "value":"0810004"
  },
  {
    "label":"6911703 - agente de propriedade industrial",
    "value":"6911703"
  },
  {
    "label":"1340502 - alvejamento, tingimento e torcao em fios, tecidos, artefatos texteis e pecas do vestuario",
    "value":"1340502"
  },
  {
    "label":"5812300 - edicao de jornais",
    "value":"5812300"
  },
  {
    "label":"1220499 - fabricacao de outros produtos do fumo, exceto cigarros, cigarrilhas e charutos",
    "value":"1220499"
  },
  {
    "label":"4649409 - comercio atacadista de produtos de higiene, limpeza e conservacao domiciliar, com atividade de fracionamento e acondicionamento associada",
    "value":"4649409"
  },
  {
    "label":"8012900 - atividades de transporte de valores",
    "value":"8012900"
  },
  {
    "label":"4645102 - comercio atacadista de proteses e artigos de ortopedia",
    "value":"4645102"
  },
  {
    "label":"9609204 - exploracao de maquinas de servicos pessoais acionadas por moeda",
    "value":"9609204"
  },
  {
    "label":"9412001 - atividades de fiscalizacao profissional",
    "value":"9412001"
  },
  {
    "label":"5120000 - transporte aereo de carga",
    "value":"5120000"
  },
  {
    "label":"3011302 - construcao de embarcacoes para uso comercial e para usos especiais, exceto de grande porte",
    "value":"3011302"
  },
  {
    "label":"2854200 - fabricacao de maquinas e equipamentos para terraplenagem, pavimentacao e construcao, pecas e acessorios, exceto tratores",
    "value":"2854200"
  },
  {
    "label":"2632900 - fabricacao de aparelhos telefonicos e de outros equipamentos de comunicacao, pecas e acessorios",
    "value":"2632900"
  },
  {
    "label":"0729404 - extracao de minerios de cobre, chumbo, zinco e outros minerais metalicos nao-ferrosos nao especificados anteriormente",
    "value":"0729404"
  },
  {
    "label":"4223500 - construcao de redes de transportes por dutos, exceto para agua e esgoto",
    "value":"4223500"
  },
  {
    "label":"2751100 - fabricacao de fogoes, refrigeradores e maquinas de lavar e secar para uso domestico, pecas e acessorios",
    "value":"2751100"
  },
  {
    "label":"7739001 - aluguel de maquinas e equipamentos para extracao de minerios e petroleo, sem operador",
    "value":"7739001"
  },
  {
    "label":"9609205 - atividades de sauna e banhos",
    "value":"9609205"
  },
  {
    "label":"0220905 - coleta de palmito em florestas nativas",
    "value":"0220905"
  },
  {
    "label":"2021500 - fabricacao de produtos petroquimicos basicos",
    "value":"2021500"
  },
  {
    "label":"2012600 - fabricacao de intermediarios para fertilizantes",
    "value":"2012600"
  },
  {
    "label":"4681804 - comercio atacadista de combustiveis de origem mineral em bruto",
    "value":"4681804"
  },
  {
    "label":"0729405 - beneficiamento de minerios de cobre, chumbo, zinco e outros minerais metalicos nao-ferrosos nao especificados anteriormente",
    "value":"0729405"
  },
  {
    "label":"2422902 - producao de laminados planos de acos especiais",
    "value":"2422902"
  },
  {
    "label":"2814302 - fabricacao de compressores para uso nao industrial, pecas e acessorios",
    "value":"2814302"
  },
  {
    "label":"0116402 - cultivo de girassol",
    "value":"0116402"
  },
  {
    "label":"2539000 - servicos de usinagem, solda, tratamento e revestimento em metais",
    "value":"2539000"
  },
  {
    "label":"9602502 - atividades de estetica e outros servicos de cuidados com a beleza",
    "value":"9602502"
  },
  {
    "label":"4751201 - comercio varejista especializado de equipamentos e suprimentos de informatica",
    "value":"4751201"
  },
  {
    "label":"9529199 - reparacao e manutencao de outros objetos e equipamentos pessoais e domesticos nao especificados anteriormente",
    "value":"9529199"
  },
  {
    "label":"9499500 - atividades associativas nao especificadas anteriormente",
    "value":"9499500"
  },
  {
    "label":"4329104 - montagem e instalacao de sistemas e equipamentos de iluminacao e sinalizacao em vias publicas, portos e aeroportos",
    "value":"4329104"
  },
  {
    "label":"4632003 - comercio atacadista de cereais e leguminosas beneficiados, farinhas, amidos e feculas, com atividade de fracionamento e acondicionamento associada",
    "value":"4632003"
  },
  {
    "label":"1413402 - confeccao, sob medida, de roupas profissionais",
    "value":"1413402"
  },
  {
    "label":"4639702 - comercio atacadista de produtos alimenticios em geral, com atividade de fracionamento e acondicionamento associada",
    "value":"4639702"
  },
  {
    "label":"3104700 - fabricacao de colchoes",
    "value":"3104700"
  },
  {
    "label":"2592601 - fabricacao de produtos de trefilados de metal padronizados",
    "value":"2592601"
  },
  {
    "label":"3211601 - lapidacao de gemas",
    "value":"3211601"
  },
  {
    "label":"0810007 - extracao de argila e beneficiamento associado",
    "value":"0810007"
  },
  {
    "label":"3329599 - instalacao de outros equipamentos nao especificados anteriormente",
    "value":"3329599"
  },
  {
    "label":"0112101 - cultivo de algodao herbaceo",
    "value":"0112101"
  },
  {
    "label":"8640209 - servicos de diagnostico por metodos opticos - endoscopia e outros exames analogos",
    "value":"8640209"
  },
  {
    "label":"4789006 - comercio varejista de fogos de artificio e artigos pirotecnicos",
    "value":"4789006"
  },
  {
    "label":"5231102 - atividades do operador portuario",
    "value":"5231102"
  },
  {
    "label":"6470103 - fundos de investimento imobiliarios",
    "value":"6470103"
  },
  {
    "label":"4311801 - demolicao de edificios e outras estruturas",
    "value":"4311801"
  },
  {
    "label":"1931400 - fabricacao de alcool",
    "value":"1931400"
  },
  {
    "label":"9603301 - gestao e manutencao de cemiterios",
    "value":"9603301"
  },
  {
    "label":"8299703 - servicos de gravacao de carimbos, exceto confeccao",
    "value":"8299703"
  },
  {
    "label":"8640212 - servicos de hemoterapia",
    "value":"8640212"
  },
  {
    "label":"5022002 - transporte por navegacao interior de passageiros em linhas regulares, intermunicipal, interestadual e internacional, exceto travessia",
    "value":"5022002"
  },
  {
    "label":"1921700 - fabricacao de produtos do refino de petroleo",
    "value":"1921700"
  },
  {
    "label":"1922502 - rerrefino de oleos lubrificantes",
    "value":"1922502"
  },
  {
    "label":"4731800 - comercio varejista de combustiveis para veiculos automotores",
    "value":"4731800"
  },
  {
    "label":"4618499 - outros representantes comerciais e agentes do comercio especializado em produtos nao especificados anteriormente",
    "value":"4618499"
  },
  {
    "label":"0321302 - criacao de camaroes em agua salgada e salobra",
    "value":"0321302"
  },
  {
    "label":"0810001 - extracao de ardosia e beneficiamento associado",
    "value":"0810001"
  },
  {
    "label":"4541207 - comercio a varejo de pecas e acessorios usados para motocicletas e motonetas",
    "value":"4541207"
  },
  {
    "label":"3292201 - fabricacao de roupas de protecao e seguranca e resistentes a fogo",
    "value":"3292201"
  },
  {
    "label":"1082100 - fabricacao de produtos a base de cafe",
    "value":"1082100"
  },
  {
    "label":"2821601 - fabricacao de fornos industriais, aparelhos e equipamentos nao-eletricos para instalacoes termicas, pecas e acessorios",
    "value":"2821601"
  },
  {
    "label":"2441502 - producao de laminados de aluminio",
    "value":"2441502"
  },
  {
    "label":"5091202 - transporte por navegacao de travessia intermunicipal, interestadual e internacional",
    "value":"5091202"
  },
  {
    "label":"4612500 - representantes comerciais e agentes do comercio de combustiveis, minerais, produtos siderurgicos e quimicos",
    "value":"4612500"
  },
  {
    "label":"3319800 - manutencao e reparacao de equipamentos e produtos nao especificados anteriormente",
    "value":"3319800"
  },
  {
    "label":"4652400 - comercio atacadista de componentes eletronicos e equipamentos de telefonia e comunicacao",
    "value":"4652400"
  },
  {
    "label":"1032599 - fabricacao de conservas de legumes e outros vegetais, exceto palmito",
    "value":"1032599"
  },
  {
    "label":"0141501 - producao de sementes certificadas, exceto de forrageiras para pasto",
    "value":"0141501"
  },
  {
    "label":"5112901 - servico de taxi aereo e locacao de aeronaves com tripulacao",
    "value":"5112901"
  },
  {
    "label":"4643502 - comercio atacadista de bolsas, malas e artigos de viagem",
    "value":"4643502"
  },
  {
    "label":"2930103 - fabricacao de cabines, carrocerias e reboques para outros veiculos automotores, exceto caminhoes e onibus",
    "value":"2930103"
  },
  {
    "label":"2019399 - fabricacao de outros produtos quimicos inorganicos nao especificados anteriormente",
    "value":"2019399"
  },
  {
    "label":"2865800 - fabricacao de maquinas e equipamentos para as industrias de celulose, papel e papelao e artefatos, pecas e acessorios",
    "value":"2865800"
  },
  {
    "label":"3011301 - construcao de embarcacoes de grande porte",
    "value":"3011301"
  },
  {
    "label":"2399102 - fabricacao de abrasivos",
    "value":"2399102"
  },
  {
    "label":"4713005 - lojas francas (duty free) de aeroportos, portos e em fronteiras terrestres",
    "value":"4713005"
  },
  {
    "label":"1122402 - fabricacao de cha mate e outros chas prontos para consumo",
    "value":"1122402"
  },
  {
    "label":"2680900 - fabricacao de midias virgens, magneticas e opticas",
    "value":"2680900"
  },
  {
    "label":"1113501 - fabricacao de malte, inclusive malte uisque",
    "value":"1113501"
  },
  {
    "label":"1091102 - fabricacao de produtos de padaria e confeitaria com predominancia de producao propria",
    "value":"1091102"
  },
  {
    "label":"6209100 - suporte tecnico, manutencao e outros servicos em tecnologia da informacao",
    "value":"6209100"
  },
  {
    "label":"9001902 - producao musical",
    "value":"9001902"
  },
  {
    "label":"4763602 - comercio varejista de artigos esportivos",
    "value":"4763602"
  },
  {
    "label":"4520003 - servicos de manutencao e reparacao eletrica de veiculos automotores",
    "value":"4520003"
  },
  {
    "label":"6204000 - consultoria em tecnologia da informacao",
    "value":"6204000"
  },
  {
    "label":"4330405 - aplicacao de revestimentos e de resinas em interiores e exteriores",
    "value":"4330405"
  },
  {
    "label":"6470101 - fundos de investimento, exceto previdenciarios e imobiliarios",
    "value":"6470101"
  },
  {
    "label":"1629301 - fabricacao de artefatos diversos de madeira, exceto moveis",
    "value":"1629301"
  },
  {
    "label":"3212400 - fabricacao de bijuterias e artefatos semelhantes",
    "value":"3212400"
  },
  {
    "label":"4782202 - comercio varejista de artigos de viagem",
    "value":"4782202"
  },
  {
    "label":"4771702 - comercio varejista de produtos farmaceuticos, com manipulacao de formulas",
    "value":"4771702"
  },
  {
    "label":"7739003 - aluguel de palcos, coberturas e outras estruturas de uso temporario, exceto andaimes",
    "value":"7739003"
  },
  {
    "label":"7731400 - aluguel de maquinas e equipamentos agricolas sem operador",
    "value":"7731400"
  },
  {
    "label":"8220200 - atividades de teleatendimento",
    "value":"8220200"
  },
  {
    "label":"3831901 - recuperacao de sucatas de aluminio",
    "value":"3831901"
  },
  {
    "label":"3314719 - manutencao e reparacao de maquinas e equipamentos para as industrias de alimentos, bebidas e fumo",
    "value":"3314719"
  },
  {
    "label":"9609202 - agencias matrimoniais",
    "value":"9609202"
  },
  {
    "label":"5914600 - atividades de exibicao cinematografica",
    "value":"5914600"
  },
  {
    "label":"7220700 - pesquisa e desenvolvimento experimental em ciencias sociais e humanas",
    "value":"7220700"
  },
  {
    "label":"4212000 - construcao de obras de arte especiais",
    "value":"4212000"
  },
  {
    "label":"3314708 - manutencao e reparacao de maquinas, equipamentos e aparelhos para transporte e elevacao de cargas",
    "value":"3314708"
  },
  {
    "label":"4912403 - transporte metroviario",
    "value":"4912403"
  },
  {
    "label":"6433600 - bancos de desenvolvimento",
    "value":"6433600"
  },
  {
    "label":"5012202 - transporte maritimo de longo curso - passageiros",
    "value":"5012202"
  },
  {
    "label":"2449102 - producao de laminados de zinco",
    "value":"2449102"
  },
  {
    "label":"6622300 - corretores e agentes de seguros, de planos de previdencia complementar e de saude",
    "value":"6622300"
  },
  {
    "label":"4912401 - transporte ferroviario de passageiros intermunicipal e interestadual",
    "value":"4912401"
  },
  {
    "label":"5911199 - atividades de producao cinematografica, de videos e de programas de televisao nao especificadas anteriormente",
    "value":"5911199"
  },
  {
    "label":"4756300 - comercio varejista especializado de instrumentos musicais e acessorios",
    "value":"4756300"
  },
  {
    "label":"0311603 - coleta de outros produtos marinhos",
    "value":"0311603"
  },
  {
    "label":"3832700 - recuperacao de materiais plasticos",
    "value":"3832700"
  },
  {
    "label":"4632001 - comercio atacadista de cereais e leguminosas beneficiados",
    "value":"4632001"
  },
  {
    "label":"0220904 - coleta de latex em florestas nativas",
    "value":"0220904"
  },
  {
    "label":"3240003 - fabricacao de mesas de bilhar, de sinuca e acessorios associada a locacao",
    "value":"3240003"
  },
  {
    "label":"3032600 - fabricacao de pecas e acessorios para veiculos ferroviarios",
    "value":"3032600"
  },
  {
    "label":"2920401 - fabricacao de caminhoes e onibus",
    "value":"2920401"
  },
  {
    "label":"1610205 - servico de tratamento de madeira realizado sob contrato",
    "value":"1610205"
  },
  {
    "label":"0312403 - coleta de outros produtos aquaticos de agua doce",
    "value":"0312403"
  },
  {
    "label":"0729403 - extracao de minerio de niquel",
    "value":"0729403"
  },
  {
    "label":"2094100 - fabricacao de catalisadores",
    "value":"2094100"
  },
  {
    "label":"0725100 - extracao de minerais radioativos",
    "value":"0725100"
  },
  {
    "label":"1011202 - frigorifico - abate de equinos",
    "value":"1011202"
  },
  {
    "label":"2910703 - fabricacao de motores para automoveis, camionetas e utilitarios",
    "value":"2910703"
  },
  {
    "label":"4623107 - comercio atacadista de sisal",
    "value":"4623107"
  },
  {
    "label":"4530703 - comercio a varejo de pecas e acessorios novos para veiculos automotores",
    "value":"4530703"
  },
  {
    "label":"8599699 - outras atividades de ensino nao especificadas anteriormente",
    "value":"8599699"
  },
  {
    "label":"4744005 - comercio varejista de materiais de construcao nao especificados anteriormente",
    "value":"4744005"
  },
  {
    "label":"9492800 - atividades de organizacoes politicas",
    "value":"9492800"
  },
  {
    "label":"7721700 - aluguel de equipamentos recreativos e esportivos",
    "value":"7721700"
  },
  {
    "label":"4329103 - instalacao, manutencao e reparacao de elevadores, escadas e esteiras rolantes",
    "value":"4329103"
  },
  {
    "label":"5212500 - carga e descarga",
    "value":"5212500"
  },
  {
    "label":"8888888 - atividade nao informada",
    "value":"8888888"
  },
  {
    "label":"4511101 - comercio a varejo de automoveis, camionetas e utilitarios novos",
    "value":"4511101"
  },
  {
    "label":"9529104 - reparacao de bicicletas, triciclos e outros veiculos nao-motorizados",
    "value":"9529104"
  },
  {
    "label":"4520008 - servicos de capotaria",
    "value":"4520008"
  },
  {
    "label":"4649401 - comercio atacadista de equipamentos eletricos de uso pessoal e domestico",
    "value":"4649401"
  },
  {
    "label":"7210000 - pesquisa e desenvolvimento experimental em ciencias fisicas e naturais",
    "value":"7210000"
  },
  {
    "label":"4633802 - comercio atacadista de aves vivas e ovos",
    "value":"4633802"
  },
  {
    "label":"4681802 - comercio atacadista de combustiveis realizado por transportador retalhista (t.r.r.)",
    "value":"4681802"
  },
  {
    "label":"5229001 - servicos de apoio ao transporte por taxi, inclusive centrais de chamada",
    "value":"5229001"
  },
  {
    "label":"7729203 - aluguel de material medico",
    "value":"7729203"
  },
  {
    "label":"2852600 - fabricacao de outras maquinas e equipamentos para uso na extracao mineral, pecas e acessorios, exceto na extracao de petroleo",
    "value":"2852600"
  },
  {
    "label":"6612602 - distribuidoras de titulos e valores mobiliarios",
    "value":"6612602"
  },
  {
    "label":"1061902 - fabricacao de produtos do arroz",
    "value":"1061902"
  },
  {
    "label":"8621601 - uti movel",
    "value":"8621601"
  },
  {
    "label":"6621502 - auditoria e consultoria atuarial",
    "value":"6621502"
  },
  {
    "label":"6022502 - atividades relacionadas a televisao por assinatura, exceto programadoras",
    "value":"6022502"
  },
  {
    "label":"4713003 - lojas duty free de aeroportos internacionais",
    "value":"4713003"
  },
  {
    "label":"7119701 - servicos de cartografia, topografia e geodesia",
    "value":"7119701"
  },
  {
    "label":"4322303 - instalacoes de sistema de prevencao contra incendio",
    "value":"4322303"
  },
  {
    "label":"7120100 - testes e analises tecnicas",
    "value":"7120100"
  },
  {
    "label":"5911102 - producao de filmes para publicidade",
    "value":"5911102"
  },
  {
    "label":"4399105 - perfuracao e construcao de pocos de agua",
    "value":"4399105"
  },
  {
    "label":"4673700 - comercio atacadista de material eletrico",
    "value":"4673700"
  },
  {
    "label":"1069400 - moagem e fabricacao de produtos de origem vegetal nao especificados anteriormente",
    "value":"1069400"
  },
  {
    "label":"1741902 - fabricacao de produtos de papel, cartolina, papel cartao e papelao ondulado para uso comercial e de escritorio, exceto formulario continuo",
    "value":"1741902"
  },
  {
    "label":"6512000 - sociedade seguradora de seguros nao vida",
    "value":"6512000"
  },
  {
    "label":"2013402 - fabricacao de adubos e fertilizantes, exceto organo-minerais",
    "value":"2013402"
  },
  {
    "label":"1610204 - serrarias sem desdobramento de madeira em bruto  -resserragem",
    "value":"1610204"
  },
  {
    "label":"2452100 - fundicao de metais nao-ferrosos e suas ligas",
    "value":"2452100"
  },
  {
    "label":"2541100 - fabricacao de artigos de cutelaria",
    "value":"2541100"
  },
  {
    "label":"3314709 - manutencao e reparacao de maquinas de escrever, calcular e de outros equipamentos nao-eletronicos para escritorio",
    "value":"3314709"
  },
  {
    "label":"8720401 - atividades de centros de assistencia psicossocial",
    "value":"8720401"
  },
  {
    "label":"9103100 - atividades de jardins botanicos, zoologicos, parques nacionais, reservas ecologicas e areas de protecao ambiental",
    "value":"9103100"
  },
  {
    "label":"8299701 - medicao de consumo de energia eletrica, gas e agua",
    "value":"8299701"
  },
  {
    "label":"6613400 - administracao de cartoes de credito",
    "value":"6613400"
  },
  {
    "label":"4669901 - comercio atacadista de bombas e compressores; partes e pecas",
    "value":"4669901"
  },
  {
    "label":"3314717 - manutencao e reparacao de maquinas e equipamentos de terraplenagem, pavimentacao e construcao, exceto tratores",
    "value":"3314717"
  },
  {
    "label":"1622601 - fabricacao de casas de madeira pre-fabricadas",
    "value":"1622601"
  },
  {
    "label":"9102301 - atividades de museus e de exploracao de lugares e predios historicos e atracoes similares",
    "value":"9102301"
  },
  {
    "label":"1412601 - confeccao de pecas de vestuario, exceto roupas intimas e as confeccionadas sob medida",
    "value":"1412601"
  },
  {
    "label":"7020400 - atividades de consultoria em gestao empresarial, exceto consultoria tecnica especifica",
    "value":"7020400"
  },
  {
    "label":"4689399 - comercio atacadista especializado em outros produtos intermediarios nao especificados anteriormente",
    "value":"4689399"
  },
  {
    "label":"4729602 - comercio varejista de mercadorias em lojas de conveniencia",
    "value":"4729602"
  },
  {
    "label":"2342702 - fabricacao de artefatos de ceramica e barro cozido para uso na construcao, exceto azulejos e pisos",
    "value":"2342702"
  },
  {
    "label":"1422300 - fabricacao de artigos do vestuario, produzidos em malharias e tricotagens, exceto meias",
    "value":"1422300"
  },
  {
    "label":"4222701 - construcao de redes de abastecimento de agua, coleta de esgoto e construcoes correlatas, exceto obras de irrigacao",
    "value":"4222701"
  },
  {
    "label":"4771703 - comercio varejista de produtos farmaceuticos homeopaticos",
    "value":"4771703"
  },
  {
    "label":"4512901 - representantes comerciais e agentes do comercio de veiculos automotores",
    "value":"4512901"
  },
  {
    "label":"4651601 - comercio atacadista de equipamentos de informatica",
    "value":"4651601"
  },
  {
    "label":"9900800 - organismos internacionais e outras instituicoes extraterritoriais",
    "value":"9900800"
  },
  {
    "label":"2949201 - fabricacao de bancos e estofados para veiculos automotores",
    "value":"2949201"
  },
  {
    "label":"8730101 - orfanatos",
    "value":"8730101"
  },
  {
    "label":"4721104 - comercio varejista de doces, balas, bombons e semelhantes",
    "value":"4721104"
  },
  {
    "label":"7319099 - outras atividades de publicidade nao especificadas anteriormente",
    "value":"7319099"
  },
  {
    "label":"4399199 - servicos especializados para construcao nao especificados anteriormente",
    "value":"4399199"
  },
  {
    "label":"1066000 - fabricacao de alimentos para animais",
    "value":"1066000"
  }
]