import axios from "axios";

const api = axios.create({
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: "csrftoken",
  baseURL: (process.env.NODE_ENV === "production" ? "/":"http://localhost:8000"),
})

api.defaults.headers.post['Content-Type'] = 'application/json';
api.defaults.withCredentials = true;

export default api;
