import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Button, Flex, HStack, Spacer, Text, VStack, useToast, AccordionIcon, CircularProgress } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

import api from "../../api"
import { SearchIcon } from "@chakra-ui/icons";

import { createList } from "../ExportList/api";

import ExportList from "../ExportList";
import { useNavigate } from "react-router-dom";

const Statistics = ({ count, params }) => {
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <HStack borderRadius="8px" background="#fff" border={"1px solid #e7e7e7"} padding="24px" w="100%">
        <Text as="h2" fontSize='xl' fontWeight={700}><strong>{ count }</strong> contato(s)</Text>
        <Spacer />
        <Button bg="#417B5A" color="#fff" onClick={() => setOpened(true)}>Adicionar Lista</Button>
      </HStack>

      <ExportList onFinished={() => navigate("/listas/")} title="Adicionar lista" open={opened} setOpened={setOpened} params={params} count={count} api={createList} />
    </>
  )
}

const Lead = ({ data }) => {
  const cnpj = `${data.cnpj.slice(0,2)}.${data.cnpj.slice(2,5)}.${data.cnpj.slice(5,8)}/${data.cnpj.slice(8,12)}-${data.cnpj.slice(12)}`

  return (
    <AccordionItem border={0} pb="16px">
      <div>
        <AccordionButton border={0} padding={0} _expanded={{borderRadius: "8px 8px 0 0", borderBottom: 0}} _hover={{ cursor: "pointer"}} borderRadius={"8px"} background="#fff" border={"1px solid #e7e7e7"}>
          <VStack w="100%" spacing={4} transition="0.3s all"  p="24px" justifyItems={"start"}>
            <HStack w="100%">
              <Text><Text as="b">CNPJ</Text>: {cnpj}</Text>
              <Text> - {data.name}</Text>
              <Spacer />
              <AccordionIcon width='24px' height='24px' />
            </HStack>
          </VStack>
        </AccordionButton>
      </div>

      <AccordionPanel p="24px" background="#fff" borderRadius="0 0 8px 8px" border="1px solid #e7e7e7" borderTop="0">
        <VStack>
          {/* <VStack>
            <Text>{data.simples}</Text>
            <Text>{data.mei}</Text>
          </VStack> */}
          <HStack spacing={8} w="100%">
            <Text>{data.uf}, {data.municipio}</Text>
            <Spacer />
            <Text>R$ {data.capital_social}</Text>
          </HStack>
        </VStack>
      </AccordionPanel>
    </AccordionItem>
  )
}

const LeadContainer = ({ estabelecimentos }) => {
  return (
    <Accordion w="100%">
      {estabelecimentos.map((data, i) => <Lead data={data} key={i} />)}
    </Accordion>
  )
}

const SearchNotFound = () => {
  return (
    <Flex direction={"column"} justifyContent="center" justifyItems={"center"} alignItems={"center"} flex="1" h="100%">
      <VStack mt="-128px">
        <SearchIcon width='24em' height='24em' color='#e7e7e7' />
        <Text as="h2" fontWeight={700} fontSize={'24px'} mt="32px" color='#a7a7a7'>Realize uma busca com o formulário ao lado</Text>
      </VStack>
    </Flex>
  )
}

const FilterProcessing = () => {
  return (
    <Flex direction={"column"} justifyContent="center" justifyItems={"center"} alignItems={"center"} flex="1" h="100%">
      <VStack mt="-128px">
        <CircularProgress isIndeterminate size="100px" color="green" />
        <Text as="h2" fontWeight={700} fontSize={'24px'} mt="32px" color='#a7a7a7'>Aguarde enquanto estamos buscando os dados!</Text>
      </VStack>
    </Flex>
  )
}

const List = ({ params }) => {
  const [data, setData] = useState({ count: 0, estabelecimentos: [] });
  const updateFilter = useRef(-1)

  useEffect(() => {
    setData({ count: 0, estabelecimentos: [] });

    if (params.id !== undefined) {
      const update = () => {
        api.get(`/filter/buscar/${params.id}/`).then((response) => {
          if (response.data.status === "success") {
            setData(response.data);
          } else {
            setTimeout(update, 1000);
          }
        });
      }
      
      updateFilter.current = setTimeout(update, 1000);
    }

    return () => clearTimeout(updateFilter.current);
  }, [params]);

  if (params.id === undefined) {
    return <SearchNotFound />;
  }

  if (data?.status !== "success") {
    return <FilterProcessing />;
  }

  return (
    <VStack flex="1" spacing={4} h="100%" w="100%">
      <Statistics count={data.count} params={params} />
      <LeadContainer estabelecimentos={data.contacts} />
      <Spacer />
    </VStack>
  )
}

export default List;