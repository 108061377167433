import {
  Box,
  FormControl,
  FormLabel,
  Button,
  FormHelperText,
  Flex,
  FormErrorMessage,
  Select,
  InputGroup,
  InputRightElement,
  HStack,
} from '@chakra-ui/react'
import { useFormik } from "formik"

import { useContext, useEffect, useState } from 'react';
import { CreateContext } from './context';

const StepFileExtension = ({ goTo, updateContext }) => {
  const ctx = useContext(CreateContext);
  const formik = useFormik({
    onSubmit: (values) => {
      updateContext({ ...ctx, file_extension: values.file_extension });
      goTo();
    },
    initialValues: { file_extension: "xlsx" }
  })

  return (
    <Box mb='2em'>
      <form onSubmit={formik.handleSubmit}>
        <FormControl isRequired isInvalid={formik.errors?.file_extension}>
          <FormLabel>Escolha o tipo de planilha</FormLabel>
          <HStack>
            <Select value={formik.values.file_extension} name="file_extension" onChange={formik.handleChange}>
              <option value="csv">CSV</option>
              <option value="xlsx">Excel</option>
            </Select>
            <Button type='submit' colorScheme='teal'>Continuar</Button>
          </HStack>
          <FormHelperText>Selecione o tipo de arquivo.</FormHelperText>
          <FormErrorMessage>{formik.errors?.count}</FormErrorMessage>
        </FormControl>

      </form>
    </Box>
  );
}

export default StepFileExtension;