import { useEffect, useRef, useState } from 'react'
import {
  Text, Tooltip, Link, CircularProgress, HStack, Flex, Tag, VStack, Spacer
} from '@chakra-ui/react'

import { DownloadIcon, WarningIcon } from "@chakra-ui/icons"
import { useNavigate } from 'react-router-dom'

import { FAILED, INITIATED, PROCESSING, SUCCESS } from "../../utils/status"

import Button from '../Button'

import api from '../../api'
import ExportList from '../ExportList'
import { createPartialList } from '../ExportList/api'

import moment from 'moment'

const get_button = (data, openModal) => {
  if (data.last_list?.status === SUCCESS) {
    return (
      <>
        <Tooltip label="Última lista gerada"><Link href={data.last_list.sheet_file} download={true}><DownloadIcon /> Baixar</Link></Tooltip>
        {data.contacts_downloaded < data.contacts_count && <Button onClick={() => openModal(data)}>Continuar</Button>}
      </>
    );
  } else if (data.last_list?.status === PROCESSING) {
    return (
      <Tooltip label="Sua lista está em processamento"><CircularProgress size='20px' isIndeterminate color='green.300' /></Tooltip>
    );
  } else if (data.last_list?.status === FAILED) {
    return <Tooltip label="Falhou em processar sua lista"><WarningIcon color={"#e53e3e"} /></Tooltip>
  }
}

const tagLabel = (value) => {
  switch(value) {
    case 'uf':
      return 'Estado';
    case 'cnae_fiscal':
      return 'CNAE';
    case 'exclude_mei':
      return 'Excluir MEI?'
    case 'tem_celular':
      return 'Somente celular?'
  }

  return value
}

const mapValue = (value) => {
  if (value instanceof Array) {
    return value.join(', ');
  } else if (typeof(value) === 'boolean') {
    if (value) { return "Sim" } else { return "Não" }
  }

  return value
}

const ListItem = ({ data, openModal }) => {
  const [currentData, setCurrentData] = useState(data);
  const timeUpdated = useRef(-1);

  useEffect(() => {
    const updateDetail = () => {
      api.get(`/filter/info/${data.id}`)
        .then((response) => {
          setCurrentData(response.data);

          if (response.data.last_list.status === undefined ||
              response.data.last_list.status === PROCESSING ||
              response.data.last_list.status === INITIATED) {
            timeUpdated.current = setTimeout(updateDetail, 5000);
          }
        });
    };

    clearTimeout(timeUpdated.current);

    if (currentData.last_list.status === undefined ||
        currentData.last_list.status === PROCESSING ||
        currentData.last_list.status === INITIATED) {
      timeUpdated.current = setTimeout(updateDetail, 5000);
    }

    setCurrentData(data);
  }, [data]);

  return (
    <Flex gap={8} p="24px" background="#fff" w="100%" border="1px solid #e7e7e7" borderRadius="8px">
      <HStack>
        {Object.keys(currentData.filter_data).filter((x) => currentData.filter_data[x] !== null).map((value) => {
          return <Tag>{tagLabel(value)}: {mapValue(currentData.filter_data[value])}</Tag>
        })}
      </HStack>
      <Spacer />
      <HStack spacing={8}>
        <Text>{moment(currentData.created_at, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm")}</Text>
        <Tooltip label="Contatos processados em relação ao total"><Text>{currentData.contacts_downloaded}/{currentData.contacts_count}</Text></Tooltip>
        {get_button(currentData, openModal)}
      </HStack>
    </Flex>
  )
}

const LastListDisplay = () => {
  const [update, setUpdate] = useState(0);
  const [opened, setOpened] = useState(false);
  const [currentList, setCurrentList] = useState(null);
  const [lists, setLists] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    api.get("/filter/")
      .then((response) => {
        setLists(response.data)
      })
      .catch(() => navigate("/"))
  }, [update]);

  const openModal = (list) =>  {
    setCurrentList(list);
    setOpened(true);
  };

  return (
    <VStack w="100%" spacing={8}>
      <Text w="100%" fontWeight={700} fontSize={"32px"}>Minhas Listas</Text>
      {lists.map((data, i) => {
        return <ListItem data={data} key={i} openModal={openModal} />
      })}
      <ExportList
        params={currentList}
        open={opened}
        setOpened={setOpened}
        onFinished={() => { setOpened(false); setUpdate(update + 1); }}
        api={createPartialList} title="Continuar a exportar lista"
      />
    </VStack>
  )
}

export default LastListDisplay;