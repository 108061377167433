import { useState } from 'react'
import {
  Box, FormControl, FormLabel,
  Stack, Flex, FormErrorMessage,
  VStack, NumberInput, HStack, Switch, NumberInputField,
  Input
} from '@chakra-ui/react'

import DatePicker from "react-datepicker";

import { Select, AsyncSelect } from "chakra-react-select"
import { useFormik } from "formik"

import Button from '../../components/Button'
import cnaes from "../../utils/cnaes"
import states from "../../utils/states"
import cities_data from "../../utils/cities_data"
import api from '../../api'

const state_data = states.map(([uf, name]) => {
  return  {
    label: name,
    value: uf
  }
})

const validate = (values, props) => {
  const errors = {};

  // if (!values?.estado) {
  //   errors.estado = 'Campo obrigatório';
  // }
  console.log(values)
  
  if (!values?.cnae || values.cnae.length === 0) {
    errors.cnae = 'Campo obrigatório';
  }

  return errors;
}

let onSubmit = () => {}

const FILTER_INITIAL_DATA = {
  estado: null, cidade: null, cnae: [],
  share_capital_less: '', share_capital_more: '',
  mei: false, only_cellphone: false,
  opened_date_less: null, opened_date_gte: null
}

const parseDate = (date) => {
  if (date)
    return date.toJSON().split("T")[0];
}

const Filter = ({ broadcastUpdate }) => {
  const [cities, setCities] = useState([]);
  const formik = useFormik({
    onSubmit,
    validate,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: FILTER_INITIAL_DATA
  })

  onSubmit = ({ estado, cidade, cnae, mei, only_cellphone, share_capital_more, share_capital_less, opened_date_gte, opened_date_less }) => {
    const cnae_fiscal = cnae.map((x) => x.value);
    
    console.log(cnae_fiscal); 
    api.post("/filter/buscar/", { filter_data: {
      uf: estado?.value,
      municipio: cidade?.value,
      cnae_fiscal,
      exclude_mei: mei,
      tem_celular: only_cellphone,
      capital_social_less: parseFloat(share_capital_less),
      capital_social_more: parseFloat(share_capital_more),
      data_abertura_less: parseDate(opened_date_less),
      data_abertura_gte: parseDate(opened_date_gte)
    }}).then((response) => {
      broadcastUpdate(response.data);
      formik.setSubmitting(false);
    })

    formik.setSubmitting(true);
  };

  const onChangeState = (value) => {
    if (value) {
      setCities(cities_data.filter((x) => x.state === value.value));
    } else {
      setCities([])
    }

    if (value !== formik.values.estado) {
      formik.setFieldValue("cidade", null);
    }

    formik.setFieldValue("estado", value);
  }

  return (
    <Box overflow={"hidden"} overflowY={"auto"} height={"100%"} maxH={"100%"} border={"1px solid #e7e7e7"} borderRadius={"8px"} flex={["unset","unset","unset","unset", 0.4, 0.3]} w="100%" p={8} background={"#fff"} boxShadow={"0 2px 5px #f5f5f5"}>
      <form onSubmit={formik.handleSubmit}>
        <Stack direction={["column","column","row", "row", "column"]} spacing={4}>
        <FormControl isRequired isInvalid={formik.errors?.cnae}>
            <FormLabel htmlFor='cnae'>CNAE</FormLabel>
            <AsyncSelect
              name="cnae"
              isMulti
              isDisabled={formik.isSubmitting}
              placeholder="Selecionar um CNAE..."
              onChange={(value) => { formik.setFieldValue("cnae", value) }}
              value={formik.values.cnae}
              isSearchable={true}
              loadOptions={(inputValue, callback) => {
                setTimeout(() => {
                  const values = cnaes.filter((i) =>
                    i.label.toLowerCase().includes(inputValue.toLowerCase())
                  );
                  callback(values);
                }, 50);
              }}
              />
            <FormErrorMessage>{formik.errors?.cnae}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={formik.errors?.estado}>
            <FormLabel>Estado</FormLabel>
            <Select
              name="estado"
              isDisabled={formik.isSubmitting}
              isClearable={true}
              placeholder="Selecionar um estado..."
              options={state_data}
              isSearchable={true}
              value={formik.values.estado}
              onChange={onChangeState} />
            <FormErrorMessage>{formik.errors?.estado}</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>Cidade</FormLabel>
            <Select
              name="cidade"
              isDisabled={formik.isSubmitting}
              isClearable={true}
              placeholder="Selecionar uma cidade..."
              options={cities}
              value={formik.values.cidade}
              onChange={(x) => formik.setFieldValue("cidade", x)} />
          </FormControl>
        </Stack>
        <VStack w={"100%"} spacing={2}>
          <Stack direction={["column"]} spacing="4" mt={4} w="100%">
          <FormControl>
              <FormLabel>Data de abertura</FormLabel>
              <HStack>
                <DatePicker
                  dateFormat={"dd/MM/yyyy"}
                  locale="pt-br"
                  selected={formik.values.opened_date_gte}
                  placeholderText='A partir de'
                  customInput={<Input type='text' name='opened_date_gte' />}
                  onChange={(date) => formik.setFieldValue("opened_date_gte", date)}
                />
                <DatePicker
                  dateFormat={"dd/MM/yyyy"}
                  placeholderText='Até'
                  locale="pt-br"
                  selected={formik.values.opened_date_less}
                  customInput={<Input type='text' name='opened_date_less'/>}
                  onChange={(date) => formik.setFieldValue("opened_date_less", date)}
                />
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel>Capital Social</FormLabel>
              <HStack>
                <NumberInput value={formik.values.share_capital_less} onChange={formik.handleChange} >
                  <NumberInputField
                    placeholder='A partir de'
                    name="share_capital_less"
                    onChange={formik.handleChange} />
                </NumberInput>
                <NumberInput value={formik.values.share_capital_more} onChange={formik.handleChange}>
                  <NumberInputField
                    placeholder='Até'
                    name="share_capital_more"
                    onChange={formik.handleChange}/>
                </NumberInput>
              </HStack>
            </FormControl>
            <Flex direction={"row"} gap={4} w="100%">
              <FormControl inlineSize={"180px"}>
                <FormLabel>Excluir MEI?</FormLabel>
                <Switch isChecked={formik.values.mei} onChange={(e) => formik.setFieldValue("mei", e.target.checked)} />
              </FormControl>
              <FormControl>
                <FormLabel>Somente celular?</FormLabel>
                <Switch isChecked={formik.values.only_cellphone} onChange={(e) => formik.setFieldValue("only_cellphone", e.target.checked)} />
              </FormControl>
            </Flex>
          </Stack>
        </VStack>
        <Button
          mt={"32px"}
          type="submit"
          isLoading={formik.isSubmitting}
          w="100%"
          >Buscar</Button>
      </form>
    </Box>
  );
}


export default Filter;