export const INITIATED = "initiated"
export const PROCESSING = "processing"
export const FAILED = "failed"
export const SUCCESS = "success"

const LIST_STATUS = {
  [INITIATED]: "Solicitado",
  [PROCESSING]: "Processando",
  [FAILED]: "Falhou",
  [SUCCESS]: "Sucesso"
}

export default (x) => LIST_STATUS[x];
