import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Login from './pages/login';
import Filter from './pages/filter';
import MyLists from './pages/my_lists';
import Authenticated from './pages/authenticated';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route index element={<Login />} />
          <Route element={<Authenticated />}>
            <Route index path="buscar" element={<Filter />} />
            <Route path="listas" element={<MyLists />} />
          </Route>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
