
import { Box, Flex, Spacer, VStack } from '@chakra-ui/react';
import Navbar from '../../components/Navbar'

import {
  Outlet,
} from 'react-router-dom';


const Authenticated = (props) => {
  console.log(props);
  return (
    <div>
      <Flex bg={"#5747c5"} justifyContent={"end"} alignItems="center" gap="8px" w="100%" h="24px" pl="16px" pr="16px" fontSize="12px" fontWeight={"700"} color="#fff">
        <Box borderRadius={"100%"} w="12px" height="12px" bg="#5bdf5b"></Box><Box> 24.037.009 empresas ativas | Base atualizada Outubro/2024</Box>
      </Flex>
      <Navbar />
      <Outlet />
    </div>
  );
}

export default Authenticated;