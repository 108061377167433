import { useState } from 'react'
import { Box, Stack } from '@chakra-ui/react'

import { ListUpdateContext } from '../../contexts/list_update'
import Filter from '../../components/Filter'
import FilterBody from '../../components/FilterBody'


const List = () => {
  const [update, setUpdate] = useState({});

  return (
    <ListUpdateContext.Provider value={update}>
      <Stack p={4} pt={0} h={["100%", "100%", "100%", "100%", "calc(100vh - 100px)"]} direction={["column", "column", "column", "column", "row"]} spacing={"24px"} alignItems={"start"}>
        <Filter broadcastUpdate={setUpdate} />
        <FilterBody params={update} />
      </Stack>
    </ListUpdateContext.Provider>
  );
}

export default List;