import { Box } from "@chakra-ui/react";
import UserList from "../../components/UserList";


const MyLists = () => {
  return (
    <Box p="16px" pt={0}>
      <UserList />
    </Box>
  )
}

export default MyLists;