import { Box, Text, Button, useToast } from "@chakra-ui/react";
import { useContext } from "react";
import { CreateContext } from "./context";

const StepConfirmation = ({ params, api, onFinished }) => {
  const data = useContext(CreateContext);
  const toast = useToast();

  const onClick = () => {
    api(params, data, toast, onFinished);
  }

  return (
    <Box>
      <Text>Deseja confirmar?</Text>
      <Button colorScheme='teal' onClick={onClick}>Criar</Button>
    </Box>
  )
}

export default StepConfirmation;
