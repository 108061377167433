import { Avatar, Box, Flex, Image, Link, Spacer, Text, Menu, MenuButton, MenuItem, MenuList, Button, HStack } from "@chakra-ui/react";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import Logo from "../../assets/logo.svg"
import { Link as RouterLink } from "react-router-dom";

import api from "../../api";
import { useEffect, useState } from "react";

const Navbar = () => {

  const [name, setName] = useState();

  useEffect(() => {
    api.get("/user/")
      .then((response) => setName(response.data.name));
  }, [])

  return (
    <Box w="100%" padding="16px 1rem 16px 1rem">
      <Flex align={"center"}>
        <Flex alignItems={"end"} gap={"16px"}>
          <Image src={Logo} w="42px" /> 
          <Text fontSize={"24px"} fontWeight={"bold"}>Croper</Text>
        </Flex>
        <Spacer />
        
        <HStack spacing={8}>
          <RouterLink to="/buscar/">Buscar</RouterLink> 
          <RouterLink to="/listas/">Minhas Listas</RouterLink> 
          <Menu>
            <MenuButton as={Button} background="transparent" rightIcon={<HamburgerIcon />}>
              <Avatar size={"xs"} name={name} mr="8px" />Olá, {name}
            </MenuButton>
            <MenuList>
              <MenuItem as={Link} href="/logout">
                Sair
              </MenuItem>
            </MenuList> 
          </Menu>
        </HStack>
      </Flex>
    </Box>
  )
}

export default Navbar;