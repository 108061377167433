import { Box, FormLabel, FormControl, Input, VStack, Text, Image, Flex, Alert, AlertIcon, Spacer } from '@chakra-ui/react';
import { Formik } from 'formik'

import api from '../../api';
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/logo.svg"
import Button from '../../components/Button';
import { useState } from 'react';


const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    setError(null);

    api.post('/login/', {
      username: values.email, password: values.password
    }).then((response) => {
      console.log(response); 

      navigate("/buscar/");
    }).catch((error) => {
      setSubmitting(false);
      setError(error.response.data?.non_field_errors);
    });
  }

  return (
    <Flex h={["auto", "auto", "100vh"]} direction={["column", "column", "row"]}>
      <Flex flex={0.5} direction={["row", "row", "column"]} alignItems={"center"} justifyContent={["space-around", "space-around", "center"]} p={["64px", "64px", "0"]}>
        <Image w={[120, 120, 400]} src={Logo} />
        <Text as="h1" mt="32px" fontSize={"32px"} fontWeight={"bold"}>Croper</Text>
      </Flex>
      <Flex h="100%" flex={0.5} alignItems="center" justifyContent="center" background="#fff" >
        <Box w="100%" maxW="700px" p="64px">
          {error && <Alert status='error' mb={"32px"}><AlertIcon /> {error}</Alert>}
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={onSubmit}
          >
            {({values, errors, handleSubmit, handleBlur, handleChange, isSubmitting}) => (
              <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                  <FormControl>
                    <FormLabel>E-mail</FormLabel>
                    <Input
                      placeholder="Digite seu e-mail..."
                      name="email"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}/>
                    {errors?.email && <div>{errors.email}</div>}
                  </FormControl>
                  <FormControl>
                    <FormLabel>Senha</FormLabel>
                    <Input
                      placeholder='Digite sua senha...'
                      name="password"
                      type='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}/>
                    {errors?.password && <div>{errors.password}</div>}
                  </FormControl>
                  <Button type="submit" isLoading={isSubmitting} w={"100%"} backgroundColor={"#5747c5"} color="#fff" _hover={{backgroundColor: "#fff"}}>Entrar</Button>
                </VStack>
              </form>
            )}
          </Formik>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Login;