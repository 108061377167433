import {
  Box,
  Input,
  FormControl,
  FormLabel,
  Button,
  CircularProgress,
  FormHelperText,
  Flex,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  HStack,
} from '@chakra-ui/react'
import { useFormik } from "formik"

import { useEffect, useState } from 'react';

import api from "../../api"

const StepPaginationLimit = ({ count, goTo, updateContext }) => {
  const [available, setAvailable] = useState(-1);
  const formik = useFormik({
    onSubmit: (values) => {
      updateContext({ available, count: values.count });
      goTo();
    },
    validate: (values, props) => {
      let value = parseInt(values.count);

      if (count < value) {
        return { count: 'Há menos contatos disponíveis.' }
      }

      if (available < value) {
        return { count: 'Você não tem limite suficiente!'}
      }
    },
    initialValues: { count }
  })

  useEffect(() => {
    api.get("/filter/limit/").then((response) => {
      setAvailable(response.data.available);
    })
  }, [])

  if (available === -1) {
    return (
      <Flex p="32px" w="100%" h="100%" alignContent={"center"} justifyContent={"center"}><CircularProgress isIndeterminate /></Flex>
    );
  }

  return (
    <Box mb='2em'>
      <form onSubmit={formik.handleSubmit}>
        <FormControl isRequired isInvalid={formik.errors?.count}>
          <FormLabel>Quantidade contatos</FormLabel>
          <HStack>
            <Input value={formik.values.count} name="count" onChange={formik.handleChange} />
            <Button type='submit' colorScheme='teal'>Continuar</Button>
          </HStack>
          <FormHelperText>Você pode baixar no máximo {available} contatos esse mês</FormHelperText>
          <FormErrorMessage>{formik.errors?.count}</FormErrorMessage>
        </FormControl>
      </form>
    </Box>
  );
}

export default StepPaginationLimit;