import {
  useSteps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react'

import StepPaginationLimit from './step_limit';
import StepConfirmation from './step_confirmation';
import StepFileExtension from './step_file_extension';
import { CreateContext } from './context';
import { useState } from 'react';

const PHASES = [StepPaginationLimit, StepFileExtension, StepConfirmation];

const CreateStepper = ({ setOpened, params, count, api, onFinished={onFinished} }) => {
  const [ctx, updateContext] = useState({});
  const { activeStep, goToNext } = useSteps({ index: 1, count: 3 });

  const Phase = PHASES[activeStep -1];

  return (
    <Box position='relative'>
      <CreateContext.Provider value={ctx}>
        <Phase
          goTo={goToNext}
          params={params}
          count={count}
          api={api}
          onFinished={onFinished}
          updateContext={updateContext}  />
      </CreateContext.Provider>
    </Box>
  )
}

const CreateList = ({ title, open, setOpened, params, count, api, onFinished }) => {
  return (
    <Modal isOpen={open} onClose={() => setOpened(false)} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateStepper
            setOpened={setOpened}
            params={params}
            api={api}
            onFinished={onFinished}
            count={count} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}


export default CreateList;